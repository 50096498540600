.footer{
    display: flex;
    width: 1362px;
    height: 495px;
    border-top: 3px solid #E9EDF2;
    font-family: serif;
}
.footer_1st{
    width: 310px;
}
.footer_2nd{
    width: 780px;
    text-align: left;
}
.foter_logo{
    width: 160px;
    height: 50px;
    margin: 34px 0px 0px;
}
.footer_content{
    margin: 0px 0px 0px 24px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 20px;
}
.fo_heading{
    width: 722px;
    height: 54px;
    font: 40px/54px source serif Pro;
    margin: 29px 0px 0px 15px;
   
}
.m_7{
    margin: 0px 0px 0px 7px;
}
.fo_w_600{
   font-weight: 600;
}
.font_20{
    font: 20px/28px source serif Pro;
}
.m_icons{
    margin: 30px 0px 0px 7px;
    display: flex;
    gap: 5px;
}
.foter_sec{
    text-align: left;
}
.fo_italic{
    font-style: italic;
}
.fo_img{
    width: 100%;
    height: 100%;
    opacity: 1;
}
.w_600{
    font-weight: 700 !important;
}

.md_fo_content{
    display: flex;
    margin: 25px 0px 0px 0px;
    gap: 87px;
    font-size: 20px;


}
.fo_bea{
    margin: 20px 0px 0px 0px;
}

.footer_nav{
    text-decoration: none;
    color: #000000;
}

.font_18{
    font: 18px/25px source serif Pro;
}

.mt_15{
margin: 15px 0px 0px;
}
.ho:hover{
color: green;
}
.footer_3rd{
    margin: 107px 0px 0px 20px;
}

.m_link_icons{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fo_decri{
    margin: -40px 0px 0px 20px;
}
.fo_content_{
    width: 663px;
    text-align: left;
    font: 11px/15px source serif Pro;
    height: 30px;
    display: inline-block;
    font-style: italic;
    letter-spacing: 0px;
    color: #000000;
}
.fo_decri span {
    display: inline-block;
    margin: 0px 0px 0px -328px;
   
    font: italic normal normal 11px/15px Source Serif Pro;
    letter-spacing: 0px;
    color: #000000;
}
.fo_holo{
    text-align: end;
    font: italic 15px/21px source serif Pro;
    margin: -11px 16px 0px 0px;
}

.ico_left{
    margin-left: 0px;
}

.ml_25{
    margin: 0px 0px 0px 25px;
}