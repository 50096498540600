.mi_ve_main {
  width: 100%;
  height: 929px;
  margin: 100px 0px 27px 0px;
  background-color: rgba(28, 95, 69, 0.7);
}
.mi_ve_content {
  width: 93%;
  margin: 30px 0px 0px 52px;
  font: normal 20px/28px source serif Pro;
  color: #ffffff;
  opacity: 1;
}
