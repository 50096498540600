.mark_main {
  margin: 100px 0px 65px 0px;
  background: rgba(255, 203, 203, 0.75);
  width: 100%;
  height: 666px;
  display: flex;
  gap: 50px;
  font-family: serif;
}
.top_heading {
  text-align: left;
  font: normal 701 40px/54px Source Serif Pro;
}
.top_content {
  font: 25px/34px Source Serif Pro;
  text-align: left;
  /* font-family: serif; */
}
.mark_heading {
  opacity: 1;
  width: 88%;
}
.mark_content {
  /* width: 955px;
  height: 272px; */
  opacity: 1;
}
.mark_2_heading {
  width: 95%;
}
.mark_3_heading {
  /* width: 588px; */
  /* height: 82px; */
}
.mark_2_content {
  width: 90%;
}
.mark_3_content {
  /* width: 624px; */
  /* height: 100px; */
}
.mark_2_2_sec {
  display: flex;
  gap: 30px;
  flex-direction: column;
  width: 53.46%;
}
.smark_3_sec_content {
  margin: 40px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 51%;
}
.mark_2_cont {
  width: 88%;
}
.mark_2_mr_17 {
  margin: -7px 0px 0px 0px;
}

.gap_mark_27 {
  gap: 27px !important;
}
._mark_27 {
  width: 99%;
}
.mar_3_sec_img {
  width: 48.84%;
  height: 550px;
  opacity: 1;
}

.mark_4_sec {
  display: flex;
  gap: 50px;
  height: 542px;
  font-family: serif;
  width: 95.90%;
  margin: 0px 0px 79px 2.6%;
}

.mark_2_sec_new{
  width: 53.66%;
}
.mark_4_sec_heading {
  width: 88%;
}
.mark_4_sec_content {
  width: 100%;
}
.sale_4_sec_cont {
  width: 97.42%;
  gap: 25px !important;
}
.mark_5_sec {
  width: 67.73%;
  margin: 0px 0px 40px 17.13%;
  text-align: center;
}
.mark_6_sec {
  display: flex;
  gap: 53px;
  width: 96%;
  height: 560px;
  margin: 0px 0px 68px 48px;
  font-family: serif;
}
.mark_6_img {
  width: 44.23%;
  height: 560px;
  opacity: 1;
}
.salmarke_6_content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 51.53%;
}
.mark_6_content {
  width: 100%;
  opacity: 1;
}
.mark_6_cont {
  width: 99%;
  display: flex;
  flex-direction: column;
  gap: 15.5px;
}

.mrk_back_color {
  background: #8e0e0e;
  opacity: 100%;
}
.mrk_7_heding {
  width: 89%;
}
.mrk_8_sec {
  width: 96%;
  margin: 0px 0px 52px 2.4%;
  display: flex;
  gap: 31px;
  font-family: serif;
  height: 435px;
  opacity: 1;
}

.mrk_8_heading{
  /* width: 706px; */
  /* height: 82px; */
}
.mrk_8_content_he{
  /* height: 175px; */
}