.it_main{
    background-color: rgba(142, 183, 213, 0.75);
  
    gap: 12px;
}
.it_new_w{
    width: 71%;
}
.it_heading{
    width: 99%;
    opacity: 1;
}
.it_content{
    width: 100%;
    opacity: 1;
}

.it_1_content{
    width: 100%;
    opacity: 1;
}
.it_1_con{
    width: 529px;
}
.it_1_cont{
    width: 561px;
}
.it_2_gap{
    gap: 25px;
}
.it_2_heADING{
    width: 99%;
    opacity: 1;
}
.it_2_content{
    width: 98%;
    opacity: 1;
}
.it_2_content_w_612{
    width: 98%;
}
.it_3_heading{
    opacity: 1;
}
.it_3_content{
   
    opacity: 1;
}
.it_3_cont{
    /* width: 657px; */
}
.it_4_heading{
    width: 63.83%;
    text-align: center;
    margin: 0px 0px 40px 18%;
}
.it_5_heading{
    width: 91%;
    opacity: 1;
}
.it_5_content{
    width: 100%;
    opacity: 1;
}
.it_5_content_w{
    gap: 21px;
    width: 99%;
    display: flex;
    flex-direction: column;
}
 .it_new_wid{
    width: 54% !important;
}

.it_6_crd_back{
    background: #80337D;
    opacity: 100%;
}
.it_7_heading{
 
    opacity: 1;
}
.it_7_content{

    opacity: 1;
}