.rel_manager {
  margin: 100px 0px 75px 0px !important;
  width: 100%;
  height: 666px !important;
  background: #ffe0c9;
  opacity: 1;
}
.rel_main {
  display: flex;
  gap: 72px;
  font-family: serif;
  opacity: 1;
}
.rel_cont {
  margin: 101px 0px 0px 52px;
  text-align: justify;
  font-family: serif;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 59%;
}
.rel_header {
  width: 100%;
  font: 700 40px/54px Source Serif Pro;
  color: #000000;
}
.rel_content {
  width: 99%;
  font: 300 25px/34px Source Serif Pro;
  opacity: 1;
}
.rel_img {
  margin-top: 112px;
  width: 100%;
  height: 443px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}
.fr_hello {
  display: flex;
  gap: 70px;
  width: 96%;
  height: 550px;
  margin: 0px 0px 80px 33px;
  font-family: serif;
  opacity: 1;
}
.fr_img {
  width: 50%;
  height: 550px;
  opacity: 1;
}
.fr_content_div {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 43%;
  text-align: left;
}
.fr_cont_header {
  width: 100%;
  color: #000000;
  opacity: 1;
  margin-top: 40px;
  font: 700 30px/41px Source Serif Pro;
}
.fr_lnk {
  font: 600 20px/28px Source Serif Pro;
  margin-top: 6px !important;
}
.fr_heading {
  width: 63%;
  margin: 0px 0px 50px 18.5%;
  text-align: center;
  opacity: 100%;
  color: #000000;
  font: 700 30px/41px Source Serif Pro;
}
.crd_relation {
  width: 94%;
  margin: 0px 0px 100px 48px;
  display: flex;
  gap: 35px;
}
.rt_rating_card {
  width: 98%;
  margin: 0px 0px 74px 16px;
  height: 450px;
  background: url(../../assets/Bearishrelationshipmanagerimg/Rectangle\ 5552@2x.png);
  font-family: serif;
  background-size: 100% 100%;
  border-radius: 25px;
}
.rt_rating_heading {
  width: 91%;
  padding: 33px 0px 0px 34px;
  /* font-size: 35px; */
  text-align: left;

  opacity: 1;
  color: #ffffff;
  font: 700 35px/48px Source Serif Pro;
}
.rt_crd_main_m {
  margin: 75px 0px 0px 34px;
  gap: 30px;
}
.content_img_rt {
  width: 98%;
  display: flex;
  gap: 12px;
  margin: 0px 0px 90px 3%;
  height: 540px;
  opacity: 1;
  font-family: serif;
}
.img_back_rt {
  background: url(../../assets/Bearishrelationshipmanagerimg/Group\ 4972@2x.png);
  width: 44%;
  height: 540px;
  opacity: 100%;
  border-radius: 25px;
  position: relative;
  background-size: 100% 100%;
}

.rt_left_content_d {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  width: 54%;
}
.rt_left_content_heading {
  color: #000000;
  opacity: 100%;
  font: 700 30px/41px Source Serif Pro;
}

.rt_left_content_cont {
  width: 100%;
  text-align: left;
  opacity: 100%;
  font: 300 18px/25px Source Serif Pro;
}
.rt_left_content_icon {
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 1;
}
.widthh_100 {
  width: 100%;
}

.rt_left_content_icon_data {
  display: flex;
  gap: 15px;
}
.left_icon_content {
 
  color: #000000;

  opacity: 100%;
  font: 18px/25px Source Serif Pro;
}
.screenshot_img {
  width: 78%;
  height: 61%;
  position: absolute;
  top: 171px;
  opacity: 1;
  left: 27px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.cm_box {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 0px 0px 10px;
}

.bx_shadow {
  box-shadow: 0px 3px 6px #00000029;
}
.new_email_dec {
  width: 57%;
}
.bd_radi_5 {
  border-radius: 5px;
}
.bd_radi_25 {
  border-radius: 25px;
}

.img_box {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
}
.sec_img_tag {
  width: 78%;
  position: absolute;
  left: 22%;
  height: 48%;
  top: 16.6px;
  box-shadow: 0px 10px 10px #00000029;
}

.bold_content {
  width: 97%;
  height: 325px;
  /* background: url(../../assets/Bearishrelationshipmanagerimg/Rectangle\ 5554@2x.png); */
  margin: 0px 0px 75px 21px;
  font-family: serif;
  background-size: cover;
  opacity: 1;
  background: #2a6e59;
  border-radius: 25px;
  background-size: 100% 100%;
}
.bld_up_content {
  width: 100%;
  height: 325px;
  font-family: serif;
  background-size: 100% 100%;
  opacity: 0.5;
}
.bld_up_content_bd {
  width: 93%;
  /* height: 216px; */
  top: 15%;
  left: 3.5%;
  font: 601 40px/54px source serif Pro;
  text-align: center;
  color: #ffffff;
}
.rt_fifth_content {
  width: 1226px;
  height: 216px;
  padding: 55px 0px 0px 50px;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.5px;
  line-height: 54px;
  opacity: 1;
}
.rt_cd_heading {
  width: 60%;
  margin: 0px 0px 50px 3%;
  
  color: #000000;
  opacity: 1;
  font: 700 30px/41px Source Serif Pro;
}
.rt_crd_detail {
  margin: 0px 0px 113px 33px;
  gap: 70px 30px;
}
.lt_headding {
  text-align: left;
  font: italic 45px/62px source serif Pro;
  margin: 0px 0px 85px 7%;
  opacity: 1;
  font-style: italic;
  color: #00712b;
  width: 86%;
}
