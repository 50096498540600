.sale_main {
  margin: 100px 0px 65px 0px;
  width: 100%;
  height: 666px;
  background: url(../../assets/BearishOSSales/Rectangle\ 5548@2x.png);
  font-family: serif;
  opacity: 1;
  display: flex;
  gap: 3.7%;
}
.sale_1_sec {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 69.39%;
  margin: 101px 0px 0px 52px;
}
.sale_1_sec_heading {
  text-align: left;
  width: 94%;
  color: #e9edf2;
  font: 700 40px/54px Source Serif Pro;
}
.sale_1_sec_content {
  text-align: left;
  opacity: 1;
  color: #e9edf2;
  width: 100%;
  font: 25px/34px Source Serif Pro;
}
.sale_1_sec_img {
  width: 23.1%;
  height: 443px;
  opacity: 1;
  margin: 112px 0px 0px 0px;
  overflow: hidden;
}
.img_sale {
  width: 128%;
  height: 100%;
  opacity: 1;
  border-radius: 10px;
}
.sale_2_sec {
  width: 96%;
  margin: 0px 0px 70px 4.5%;
  height: 500px;

  display: flex;
  gap: 23px;
  font-family: serif;
}
.sale_2_sec_img {
  width: 48.84%;
  height: 500px;
  opacity: 1;
}
.sale_2_sec_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 48.53%;
}
.sale_2_sec_heading {
  width: 85%;
}
.sale_2_sec_content {
  width: 100%;
}
.sal_2_sec {
  display: flex;
  gap: 30px;
  flex-direction: column;
}
.sal_2_sec_1 {
  width: 88%;
}
.sal_2_sec_2 {
  width: 88%;
}
.sal_2_sec_3 {
  width: 88%;
}
.sale_3_sec {
  display: flex;
  width: 96%;
  margin: 0px 0px 85px 2.6%;
  gap: 5.92%;
  font-family: serif;
  opacity: 1;
}
.sale_3_sec_img {
  width: 48.84%;
  height: 550px;
  background: url(../../assets/BearishOSSales/Group\ 5017@2x.png);
  position: relative;
  opacity: 1;
  background-size: 100% 100%;
  border-radius: 25px;
}
.sale_3_sec_fr_img {
  width: 82.67%;
  height: 445px;
  position: absolute;
  top: 53px;
  left: 8.66%;
}
.sale_3_sec_content {
  margin: 40px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 44%;
}
.sale_3_sec_cont_heading {
  /* width: 556px;
  height: 82px; */
}
.sale_3_sec_cont_content {
  /* width: 572px;
  height: 50px; */
}
.sale_3_sec_w_573 {
  /* width: 573px; */
}
.sale_3_sec_h {
  margin-top: 59px;
}
.sale_4_sec {
  width: 96%;
  margin: 0px 0px 80px 2.6%;
  display: flex;
  gap: 3.8%;
  height: 542px;
  font-family: serif;
  opacity: 1;
}
.sale_4_sec_img {
  width: 42.76%;
  height: 543px;
  opacity: 1;
}
.sale_4_sec_content {
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 52.23%;
}
.sale_4_sec_heading {
  /* width: 659px;
  height: 82px; */
}
.sale_4_sec_conte {
  width: 97.23%;
}
.sale_4_sec_sm_content {
}
.gap_4_sec {
  gap: 26px;
}
.gap_5_sec {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
.gap_5_sec_content {
  font-family: serif;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  text-align: center;
}
.sale_5_sec {
  margin: 0px 0px 100px 48px;
  display: flex;
  gap: 80px 35px;
  flex-wrap: wrap;
}
.sale_5_top_img {
  width: 400px;
  height: 122px;
}
.sale_5_crd {
  width: 400px;
  height: 261px;
  opacity: 1;
  box-shadow: 5px 10px 9px 4px #00000029;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}
.sale_5_crd_content {
  position: absolute;
  bottom: 19px;
  left: 17px;
  height: 75px;
  width: 365px;
  font-size: 18px;
  text-align: left;
  line-height: 25px;
  font-family: serif;
}
.sale_5_crd_heading {
  position: absolute;
  top: 122.62px;
  left: 17px;
  height: 34px;
  font-size: 25px;
  text-align: left;
  line-height: 34px;
  font-family: serif;
}

.sale_6_sec {
  width: 96%;
  height: 560px;
  margin: 0px 0px 68px 48px;
  display: flex;
  gap: 53px;
  font-family: serif;
}
.sale_6_sec_img {
  width: 44.23%;
  height: 560px;
  opacity: 1;
}
.sale_6_content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 47.53%;
}
.sale_6_heading {
  /* width: 592px; */
  /* height: 82px; */
}
.sale_6_cont {
  /* width: 622px;
  height: 100px; */
}
.sale_6_sm_content {

  display: flex;
  flex-direction: column;
  gap: 8px;

}
.sale_7_sec {
  width: 97.73%;
  height: 450px;
  background: #486c9a;
  margin: 0px 0px 86px 16px;
  opacity: 100%;
  border-radius: 25px;
  font-family: serif;
}
.sale_7_heading {
  width: 98.46%;
  font: 35px/48px source serif Pro;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  padding: 50px 0px 0px 2.6%;
}

.sale_rating_crd {
  margin: 75px 0px 0px 34px;
  gap: 30px;
}

.sale_rating_crd_sec {
  width: 23%;
  height: 155px;
  background-size: cover;
  opacity: 1;
  display: flex;
  border-radius: 8px;
  gap: 13.5px;
  box-shadow: 0px 10px 10px #00000029;
}
.sale_rating_line {
  height: 125px;
  margin: 15px 0px 0px 13px;
}

._rate {
  width: 70px;
  font-size: 35px;
  line-height: 48px;
  text-align: left;
  font-weight: 600;
  font-family: serif;
  margin: 20px 0px 10px 0px;
}
._rate_content {
  font: 18px/25px source serif Pro;
  width: 96%;
}
.sale_8_sec {
  margin: 0px 0px 52px 2.4%;
  display: flex;
  gap: 31px;
  width: 96%;
  font-family: serif;
}
.sale_8_img {
  width: 44.23%;
  height: 435px;
  opacity: 1;
}
.sale_8_heading {
  width: 99%;
}
.sales_5th_sec_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  width: 54%;
}
.sale_8_content {
}
