.abt_main{
    width: 100%;
    height: auto;
    margin: 100px 0px 44px 0px;
    background-color: rgba(28, 95, 69, 0.7);
}
.abt_heading{
    padding: 82px 0px 0px 52px;
    color: #FFFFFF;
    font: normal 700 40px/54px Source Serif Pro;
}
.abt_content{
    width: 93%;
    margin: 30px 0px 0px 52px;
    font: normal 20px/28px source serif Pro;
    color: #ffffff;
    opacity: 1;

}
.avt_w{
    margin: 0px 0px 22px 28px;
}
.avt_padding{
    padding-bottom: 30px;
}