.op_main{
    background-color: rgba(93, 36, 142, 0.75);
    gap: 27px
}
.op_heading{
    width: 94%;
}
.op_content{
    /* width: 973px;
    height: 238px; */
    opacity: 1;
    color: #FFFFFF;
}
.w_129{
width: 129%;
opacity: 1;
height: 100%;
}
.op_1_heading{
    /* width: 590px; */
}
.op_1_content{
    /* width: 623px; */
}
.op_1_cont{
    width: 98%;
    gap: 20px;
}
.op_3_sec{
    gap: 48px;
}
.op_3_heading{
    /* width: 605px; */
}
.op_3_content{
    /* width: 645px; */
}
.op_3_cont_w{
    width: 95%;
}
.op_3_cont_w_new{
    width: 50%;
}
.op_4_heading{
    /* width: 543px;
    height: 82px; */
}
.op_4_cont{
    width: 95%;
}
.op_5_heading{
    width: 60%;
    margin: 0px 0px 40px 19.83%;
    text-align: center;
}
.op_6_content{
    /* width: 687px;
    height: 150px; */
}
.op_6_cont{
    /* height: 268px; */
    gap: 16px;
}
.op_8_heding{
    width: 83%;
}
.op_7_bg{
   background: #33806B;
   opacity: 100%;
}
.op_8_heading{
    /* width: 682px; */
}
.op_8_content{
    /* width: 680px;
    height: 150px; */
}
.letter_sp_1{
    letter-spacing: 1px;
}