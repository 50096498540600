/* .vt_par_crd_div {
  opacity: 1;
  margin: 0px 0px 86px 53px;
  display: flex;
  gap: 60px 30px;
  flex-wrap: wrap;
  width: 94%;
} */
.vt_crd {
  width: 31%;
  height: 380px;
  box-shadow: 5px 10px 11px 4px #00000029;
  border-radius: 12px;
  background-size: 100% 100%;
}
.crd_detail_h {
  width: 400px;
  height: 450px;
  background-size: 102% 101%;
}
.vt_crd_img {
  width: 326px;
  height: 187px;
  display: flex;
  justify-content: center;
  margin: 25px 0px 0px 37px;
}
.vt_crd_img_new {
  height: 187px;
  display: flex;
  justify-content: center;
  margin: 25px 0px 0px 3px;
}
.vt_rt_headg {
  margin: 33px 0px 7px 25px;
  width: 332px;
  height: 34px;
  font-size: 25px;
  text-align: left;
  font-family: serif;
  opacity: 1;
}
.vt_rt_new_head {
  margin: 80px 0px 7px 36px !important;
}
.vt_rt_new_head_margin {
  margin: 11px 0px 7px 36px !important;
}
.vt_crd_content {
  width: 331px;
  height: 75px;
  margin: 0px 0px 0px 25px;
  /* font-size: 18px; */
  /* font-family: serif;
  line-height: 25px; */
  text-align: left;
  color: #000000;
  font: 300 18px/25px Source Serif Pro;
}
.vt_w_new{
  width: 361px;
}

.vt_im_t {
  width: 348px;
  height: 121px;
  margin: 23px 7px 0px 5px;
}
.rt_card_h {
  height: 450px !important;
  box-shadow: 5px 10px 10px #00000029;
  border-radius: 10px;
  opacity: 1;
  width: 31%;
}
.rt_card_img_dif {
  width: 260px;
  height: 280px;
}
.new_img_rt {
  width: 380px !important;
  height: 222px !important;
  margin: 17px 0px 0px 0px !important;
}
.new_img_rs {
  width: 307px !important;
  height: 232px !important;
  margin: 5px 9px 0px 0px !important;
}
.new_img_rs_sec {
  width: 230px;
  height: 245px;
}
.new_img_rs_thrd {
  width: 174px;
  height: 276px;
  margin: -15px 0px 0px 0px;
}
.rt_margin {
  margin: 93px 0px 6px 38px !important;
}
.rt_margin_content {
  margin-left: 38px !important;
}
.img_w {
  width: 100%;
}
.sec2_content {
  width: 338px !important;
}
.first_im {
  width: 368px;
  height: 139px;
  margin: 50px 0px 0px 9px;
  /* display: flex; */
}
.fitth_im {
  width: 373px;
  height: 89px;
  margin: 70px 0px 0px 0px;
}
.sixth_im {
  width: 332px;
  height: 150px;
  margin: 36px 0px 0px -9px;
}
.all_im {
  height: 240px;
}
