.header {
  position: fixed;
  height: 100px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  /* width: 1366px; */
  width: 100%;
  z-index: 99;
  font-family: serif;
  top: 0;
  background: #ffffff;
}
.head_content {
  display: flex;
  width: 100%;
  font-family: serif;
  /* justify-content: space-between; */
  padding-top: 40px;
}

.new_btn {
  border: none;
  background: none;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  line-height: 28px;
  font-family: serif;
  cursor: pointer;
}

.logo {
  margin: -10px 0px 0px 35px;
  width: 40px;
  height: 41px;
}
.bearish {
  width: 25%;
}
.features {
  width: 42%;
  /* margin: -25px 5px 0px 9px; */
}

.btn {
  /* margin: -12px 0px 0px 122px; */
  margin: -14px 30px 0px 150px;
}
.ul_content {
  list-style: none;
  display: flex;
  gap: 85px;
  font-size: 20px;
  font-weight: 600;
}
.toggle-icon {
  cursor: pointer;
  padding: 0px 0px 0px 12px;
  color: #333;
}

.re_btn {
  border-radius: 6px;
  background: #e9edf2;
  cursor: pointer;
  height: 50px;
  font-family: serif;
  width: 175px;
  border: 1px solid #e9edf2;
  font: normal normal 600 20px/28px Source Serif Pro;
  letter-spacing: 0px;
}
.re_btn_new {
  border-radius: 6px;
  background: #e9edf2;
  cursor: pointer;
  height: 50px;
  font-family: serif;
  width: 175px;
  border: 1px solid #e9edf2;
  font: normal normal 600 20px/28px Source Serif Pro;
  letter-spacing: 0px;
}

.new_ham_box{
  position: absolute;
  top: 102%;
  right: 0%;
  border: 1px solid grey;
  width: 57%;
  background: #403e3e;
  border-radius: 2px;
  transition: 0.7s;
  color: white;
  box-shadow: 0px 2px 6px 2px #636262;
}

.new_ham_lis{
  padding: 14px 0px 0px;
    font: 18px / 28px source serif Pro;
    text-align: center;
    border-bottom: 1px solid grey;
    margin: 0px 5px 0px;
}

.be_logo {
  font: 20px / 20px source serif Pro;
  font-weight: 400;
  padding: 1px 6px 1px;
  margin: 66px 0px 0px 3px;
}
.open_box {
  background: #fff;
  position: absolute;
  width: 670px;
  height: 300px;
  /* margin: 16px 0px 0px 50px; */
  border-radius: 5px;
  margin-top: 34px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  transition: 0.8s ease;
}
.open_sol_box {
  background: #fff;
  /* width: 670px; */
  height: 300px;
  /* margin: 36px 0px 0px 230px; */
  border-radius: 5px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  display: flex;
  transition: 0.8s ease;
  position: absolute;
  width: 49%;
  top: 103%;
  left: 42.5%;
}

.first_div {
  width: 51%;
}
.box_logo {
  margin: 25px 1px 0px 20px;
  font-size: 20px;
  font-family: serif;
  font-weight: 600;
}
.p_data {
  margin: 15px 0px 0px 20px;
  width: 325px;
  font-size: 15px;
  text-align: left;
  height: 84px;
  font-weight: 500;
}

.he_img_ico {
  width: 30px;
  height: 30px;
}

.open_box.on-top {
  /* position: absolute;
  top: 0;
  left: 0;
  z-index: 2;  */
}

.exp_link {
  margin: 25px 0px 0px 20px;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
}
.divider {
  border-left: 3px solid #e9edf2;
  margin: 25px 0px 25px 8px;
  opacity: 1;
}
.underline-link {
  text-decoration: underline;
  color: black;
}
.core_fe {
  margin: 27px 0px 0px 20px;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
}
.ul_list {
  list-style: none;
  margin: 20px 0px 0px 0px;
}
.link {
  font-family: serif;
  color: black;
}
.li_data:not(:first-child) {
  margin-top: 20px;
  font-size: 15px;
}
.pr_color {
  color: #7691b4;
}
.pri_color {
  color: #599294;
}
.se_color {
  color: #ea006c;
}

.ec_color {
  color: #29884c;
}
.bu_color {
  color: #010101;
  font-weight: 600;
}

.fir_div {
  /* width: 30%; */
  width: 30%;
  font-family: serif;
}
.divi_der {
  /* width: 5%; */
  border-left: 3px solid#e9edf2;
  margin: 25px 0px;
  opacity: 1;
}
.secon_div {
  width: 28%;
  margin: 27px 0px 0px 5%;
  text-align: left;
}
.forth_div {
  width: 30%;
  margin: 70px 0px 0px 0px;
}

.why_b {
  margin: 25px 0px 0px 20px;
  font-size: 20px;
  font-weight: 600;
}
.use_c {
  font-size: 18px;
  font-weight: 500;
}
.ul_why {
  list-style: none;
  margin: 15px 0px 0px 22px;
}

.li_da:not(:first-child) {
  margin-top: 15px;
  font-size: 15px;
}
.s_link {
  color: black;
}
.cases_li {
  /* margin: 0px 0px 29px 0px;   */
  margin: 0px 0px 20px 0px;
  font-size: 15px;
  font-family: serif;
  display: flex;
  gap: 10px;
  /* cursor: pointer; */
}
.li_ca {
  margin-top: 20px;
}
.li_spn_da {
  margin: 5px 0px 0px 0px;
}

.sol-menu{
  display: flex;
  padding: 0px;
  width: 100%;
}
