.pl_at {
  margin: 23px 0px 23px 0px;
  font-size: 50px;
  width: 246px;
  color: #1f8db5;
  height: 69px;
  opacity: 1;
}
.par_div {
  margin: 0px 0px 0px 291px;
  width: 785px;
  height: 115px;
  font-family: serif;
  display: flex;
}
.in_so {
  margin: 23px 0px 23px 0px;
  color: #1f8db5;
  font-size: 50px;
  height: 69px;
  width: 406px;
  opacity: 1;
}
.ic_on {
  height: 115px;
  width: 113px;
  opacity: 1;
}
.crd_par_div {
  width: 1300px;
  height: 825px;
  margin: 13px 0px 0px 33px;
  background-image: url("../../assets/Group\ 4877@2x.png");
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  display: flex;
}
.crd {
  width: 400px;
  height: 750px;
  font-family: serif;
  margin: 38px -10px 0px 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}
.be_connect {
  /* width: 149px; */
  height: 28px;
  margin: 40px 0px 0px 22px;
  text-align: left;
  font-size: 20px;
  opacity: 1;
}
.be_heading{
    width: 363px;
    height: 38px;
    font-size: 28px;
    text-align: left;
    margin: 5px 0px 0px 20px;
    font-weight: 600;
}
.be_content{
    width: 356px;
    height: 150px;
    text-align: left;
    margin: 20px 0px 0px 22px;
    opacity: 1;
    font-size: 18px;
    color: #576062;
    line-height: 25px;
}
.be_icon_det{
    margin: 40px 0px 0px 22px;
    text-align: left;
}
.ic_det{
    display: flex;
    margin-bottom: 25px;
    gap: 15px;
}
.ico{
    width: 40px;
    height: 40px;
    background: url(../../assets/roundIcons/Group\ 4868.png);
}
.de{
    margin: 8px 0px;
    height: 25px;
    font-size: 18px;
    color: #576062;

}
.learn{
    margin: 33px 0px 0px 22px;
    display: flex;
    gap: 15px;
    height: 28px;
    font-size: 20px;
    font-weight: 600;
}
.screen_shot{
    margin: 44px 0px 0px 31px;
    text-align: left;
    width: 338px;
    height: 150px;
    /* border: 0.5px solid #2700FF; */
    border-radius: 5px;
}
.blueBackIcon{
    background: url(../../assets/roundIcons/Group\ 4870.png);
}
.greenBackIcon{
    background: url(../../assets/roundIcons/Group\ 4863.png);
}
.icon_img{
    margin: 10px 0px 0px 10px;
    width: 20px;
    height: 20px;
}