.hr_main{
    background-color: rgba(173, 33, 33, 0.75);
    gap: 27px;
}
.hr_heding{
    color: #FFFFFF;
    opacity: 1;
}
.hr_content{
    /* width: 966px;
    height: 272px; */
    opacity: 1;
    color: #FFFFFF;
}
.hr_new_wid_50{
    width: 50% !important;
}
.hr_1_heading{
    /* width: 655px;
    height: 82px; */
    opacity: 1;
}

.hr_head_img{
    width: 137%;
    height: 100%;
    opacity: 1;
}

.hr_1_content{
    width: 99%;
    opacity: 1;
}
.hr_1_contt{
    /* width: 607px;
    height: 200px; */
}
.hr_w_607{
    width: 607px;
    height: 50px;
}
.hr_3_gap{
    gap: 51px;
}
.hr_4_heading{
    /* width: 642px; */
}
.hr_4_content{
    width: 100%;
}
.hr_4_content_w_640{
    width: 640px;
}
.hr_5_heading{
    /* width: 575px;
    height: 82px; */
}
.hr_5_content{
    /* width: 629px;
    height: 75px; */
    opacity: 1;
}
.hr_5_conten{
    /* width: 662px;
    height: 225px; */
}
.hr_6_heading{
    width: 64.42%;
    margin: 0px 0px 40px 17.7%;
    text-align: center;
}
.hr_7_heading{
    width: 98%;
    opacity: 1;
}
.hr_7_content{
    /* width: 663px;
    height: 175px; */
    opacity: 1;
}
.hr_7_cont{
    /* height: 249px; */
    gap: 20px;
    opacity: 1;
}
.hr_7_bg{
    background: #B1A51C;
    opacity: 100%;
}