.rep_sa_main {
  margin: 100px 0px 93px 0px;
  width: 100%;
  height: 666px;
  background: url(../../assets/BearishReplacesave/Rectangle\ 5548@2x.png);
  background-size: 100% 100%;
  opacity: 1;
  display: flex;
  gap: 54px;
}
.rep_cont {
  margin: 101px 0px 0px 52px;
  width: 68%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  font-family: serif;
}
.rep_heading {
 
  margin-bottom: 0px;
  /* font-size: 40px; */
  font: 700 40px/54px Source Serif Pro;
  /* line-height: 54px; */
  opacity: 1;
}
.color_wh {
  color: #fff;
}
.rep_content {

  text-align: left;
  opacity: 1;
  font: 25px/34px Source Serif Pro;
}
.rep_2nd_sec {
  width: 96.33%;
  height: 550px;
  margin: 0px 0px 43px 2.6%;
  display: flex;
  gap: 30px;
  text-align: left;
  font-family: serif;
}
.rep_img {
  width: 51%;
  height: 550px;
  opacity: 1;
}
.new_rep_img{
  width: 46.80%;
}
.rep_heading_ {
  margin-bottom: 30px;
}
.rep_content_ {
  margin-bottom: 30px;
}
.rep_3rd_sec {
  width: 97.73%;
  height: 450px;
  background: url(../../assets/BearishReplacesave/Rectangle\ 5558@2x.png);
  margin: 0px 0px 58px 16px;
  font-family: serif;
  background-size: 100% 100%;
  opacity: 1;
  border-radius: 25px;
}
.rep_3rd_headi {
  padding: 50px 0px 0px 2.6%;
  text-align: left;
  opacity: 1;
  width: 93.83%;
  font: 700 35px/48px Source Serif Pro;
}
.rep_4th_heading {
  margin: 0px 0px 60px 19%;
  width: 67%;
}
.rep_crd_data {
  width: 98%;
  margin: 0px 0px 100px 3.5%;
  display: flex;
  gap: 50px 25px;
  flex-wrap: wrap;
}
.rep_crd_img {
  width: 353px;
  height: 150px;
  padding: 29px 5px 0px 0px;
}
.rep_5th_sec {
  display: flex;
  gap: 53px;
  width: 94%;
  margin: 0px 0px 104px 2.3%;
  font-family: serif;
  text-align: left;
  height: 435px;
  opacity: 1;
}
.rep_5th_img {
  width: 45%;
  height: 435px;
  opacity: 1;
}
.rep_5th_heading {
  width: 91%;
}
.rep_5th_content {
  width: 100%;
  margin: 25px 0px 50px 0px;
}
.rep_5th_cont {
  width: 100%;
}
.rep_5th_sign {
  font-family: serif;
  font-style: italic;
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.3px;
  opacity: 1;
}
.rep_5th_s {
  margin: 27px 0px 0px 0px;
  font-size: 15px;
}
.rep_6th_sec {
  width: 82%;
  margin: 0px 0px 20px 2.6%;
}
.rep_7th_sec {
  width: 95%;
  display: flex;
  gap: 24px;
  text-align: left;
  font-family: serif;
  margin: 0px 0px 50px 2.6%;
  height: 400px;
}
.rep_7th_img {
  width: 51%;
  height: 400px;
  opacity: 1;
}
.rep_7th_ico {
  display: flex;
  flex-direction: column;
  margin: 40px 0px 0px 0px;
  gap: 30px;
  width: 47%;
}
.rep_7th_icon {
    display: flex;
    gap: 15px;
}
.rep_7th_icon_cont {
    width: 556px;
    height: 50px;
}
