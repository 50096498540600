.cent_main {
  margin: 100px 0px 32px 0px;

  background: #fffbd095;
  width: 100%;
  height: 666px;
  opacity: 100%;
  font-family: serif;
  text-align: left;
}
.cent_1_sec {
  padding: 100px 0px 0px 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.cent_1_sec_heading {
  width: 74%;
  color: #000000;
  /* height: 207px; */
  opacity: 1;
  font: normal normal 700 50px/69px Source Serif Pro;
}
.cent_1_sec_content {
  width:61%;

  color: #000000;

  /* height: 170px; */
  font: 400 25px/34px Source Serif Pro;
  opacity: 1;
}
.cent_2_sec {
  margin: 0px 2% 139px ;
  width: 96%;
  border-radius: 25px;
  height: 325px;
  opacity: 1;
  background: url(../../assets/BearishOSCentralizedCommunications/Group\ 4940@2x.png);
  background-size: 100% 100%;
}
.cent_2_sec_content {
  /* height: 248px; */
  padding: 39px 0px 0px 0px;
  opacity: 1;
  text-align: center;
  /* font-size: 45px;
  line-height: 62px; */
  font: 700 45px/62px Source Serif Pro;
}
.cent_3_sec {
  width: 92%;
  height: 412px;
  margin: 0px 0px 158px 88.36px;
  font-family: serif;
  display: flex;
  gap: 95px;
}
.cent_3_sec_img {
  width: 34.45%;
  height: 412px;
  background: url(../../assets/BearishOSCentralizedCommunications/Screenshot\ 2024-01-15\ at\ 1.06.02\ PM@2x.png);
  background-size: 100% 100%;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  position: relative;
}
.newcent_3_sec_img{
  width: 55%;
}
.cent_3_sec_1_img {
  width: 30%;
  height: 32%;
  opacity: 1;
  top: -14%;
  position: absolute;
  left: 35%;
}
.cent_3_sec_2_img {
  width: 30%;
  left: 83%;
}
.cent_3_sec_4_img {
  width: 114px;
  position: absolute;
  top: 370px;
  left: -42px;
}
.cent_3_sec_5_img {
  position: absolute;
  left: 83%;
  top: 84%;
  width: 30%;

}
.cent_3_sec_6_img {
  position: absolute;
  left: -10%;
  top: 86%;
  width: 29%;

}
.cent_3_sec_heading {
  width:95%;
  font: 600 48px/66px Source Serif Pro;
  text-align: left;
}
.cent_3_sec_content {
  height: 170px;
  color: #000000;
  margin-top: 30px;
  font: 25px/34px Source Serif Pro;
  text-align: left;
}

.co_red {
  color: #ea006c;
}
.co_bl {
  color: #1f8db5;
}
.cent_4_sec {
  height: 62px;
  font: 600 45px/62px Source Serif Pro;
  text-align: left;
  margin: 0px 0px 73px 53px;
  opacity: 1;
  font-family: serif;
}
.cent_4_sec_crd_1_img {
  width: 340px;
  height: 200px;
  margin-top: 5px;
}
.cent_4_sec_crd_2_img {
  width: 340px;
  height: 150px;
  margin: 36px 0px 0px 0px;
}
.cent_4_sec_crd_4_img {
  width: 363px;
  height: 89px;
  margin: 79px 3px 0px 0px;
}
.cent_4_sec_crd_5_img {
  width: 342px;
  height: 152px;
  margin: 30px 3px 0px 0px;
}
.cent_4_sec_crd_6_img {
  width: 336px;
  height: 150px;
  margin: 41px 0px 0px 0px;
}
.cent_5th_sec {
  width: 100%;
  height: 450px;
  margin-bottom: 111px;
  font-family: serif;
  opacity: 1;
  background: url(../../assets/BearishOSCentralizedCommunications/Group\ 4989@2x.png);
  background-size: 100% 100%;
  border-radius: 25px;
}

.cent_5th_sec_heading {
  width: 69.32%;
  height: 96px;
  /* font-size: 35px; */
  text-align: left;
  /* line-height: 48px; */
  opacity: 1;
  padding: 50px 0px 0px 50px;
  font: 700 35px/48px Source Serif Pro;
}
.cent_rtig {
  margin: 10% 0px 0px 3%;
  gap: 30px;
}
