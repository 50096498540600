.beba_main {
  margin: 100px 0px 80px 0px;
  width: 100%;
  height: 666px;
  background: url(../../assets/BearishOSBEBAAI/Rectangle\ 5548@2x.png);
  font-family: serif;
  opacity: 1;
  display: flex;
  gap: 32px;
  background-size: cover;
}
.gap {
  gap: 20px !important;
}
.beba_w_c {
  /* width: 950px !important;
  height: 162px; */
}
.beba_w_con {
  width: 98% !important;
  letter-spacing: -0.3px;
}
.colo {
  color: #e9edf2 !important;
}
.letter_sp {
  
}
.beba_2nd_sect {
  width: 94.6%;
  margin: 0px 0px 70px 2.6%;
  font-family: serif;
  display: flex;
  gap: 54px;
  height: 540px;
}
.beba_2nd_sect_img {
  width: 39.6%;
  height: 540px;
  opacity: 1;
  background: url(../../assets/BearishOSBEBAAI/Group\ 5004@2x.png);
  background-size: 100% 100%;
  border-radius: 25px;
}
.new_be_width{
  width: 55.14% ;
}
.beba_img {
  width: 83%;
  height: 424px;
  margin: 60px 0px 0px 8%;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
border-radius: 5px;
}
.beba_sec_content {
  width: 100% !important;
}
.beba_sec_c_heading {
    margin-top: 35px;
}

.beba_3rd_section {
    margin: 0px 0px 82px 3%;
    display: flex;
    gap: 40px;
    height: 540px;
    width: 94.14%;
    font-family: serif;
}

.beba_3rd_sect_img {
  width: 46.26%;
    height: 540px;
    opacity: 1;
}
.beba_4rd_sect{
  width: 100%;
}
.beba_4rd_section{
    gap: 30px;

}
.beba_4rd_section_ico_content{
   width: 90%;
}
.beba_4rd_section_heading{
    margin: 0px 0px 25px 6%;
}
.beba_mg_btm{
   margin-bottom: 120px !important;
}

.be_crd_1st_img{
  width: 200px;
  height: 250px;
}
.be_crd_2nd_img{
  width: 257px;
  height: 257px;
}
.be_crd_3rd_img{
  width: 295px;
  height: 216px;
  margin: 8px 0px 0px 0px;
}
.beba_5th_section_{
  display: flex;
  gap: 27px;
  width: 93%;
  height: 400px;
  margin: 0px 0px 61px 3%;
  font-family: serif;
}
.beba_5th_section_div{
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
}
.beba_5th_section_heading{

}
.beba_5th_section_content{
 
}
.beba_5th_section_back{
  width: 47.26%;
  height: 325px;
  background: url(../../assets/BearishOSBEBAAI/Rectangle\ 5559@2x.png);
  margin: 75px 0px 0px 0px;
  background-size: 100% 100%;
  opacity: 1;
}
.beba_5th_section_link{
  width: 92%;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 4px;
  display: flex;
  flex-direction: column;
  font-family: none;
  gap: 5px;
  text-align: left;
  margin: 45px 0px 0px 24px;
  line-height: 25px;
}
.beba_6_heading{
  display: flex;
  justify-content: center !important;
  margin-bottom: 30px;
}
.beba_6_content{
  width: 85.5%;
  margin: 0px 0px 30px 7.8%;
  font-family: serif;
  text-align: center;
  opacity: 1;
}
.beba_lst{
  width: 85.5%;
  height: 280px;
  margin: 0px 0px 44px 7.8%;
  background: url(../../assets/BearishOSBEBAAI/Group\ 4972-1@2x.png);
  opacity: 1;
  background-size: 100% 100%;

}