.cs_1_sec {
  background-color: rgba(208, 181, 110, 0.75);

  gap: 29px;
}
.cs_1_heding {
  width: 87%;
}
.cs_1_content {
  width: 100%;
}
.cs_1_img {
  width: 24.84%;
  height: 443px;
  overflow: hidden;
  margin-top: 112px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 0px 0px 10px;
}
.cs_1_img_ {
  width: 158%;
  height: 100%;
  opacity: 1;
}
.cs_2_sec {
  width: 96%;
}
.cs_2_sec_heading {
  width: 83%;
}
.cs_2_sec_content {
  width: 99%;
}
.cs_gap_50 {
  gap: 50px;
}
.cs_3_heading {
  width: 98%;
}
.cs_3_content {
  width: 100%;
}
.cs_3_content_w_650 {
  width: 100%;
}
.cs_4_heading {
  width: 93%;
}
.cs_4_content {
  width: 100%;
}
.cs_4_cont {
  width: 100%;
}
.cs_5_sec {
    margin: 0px 0px 40px 21.4%;
    width: 57.19%;
  text-align: center;
}
.cs_6_sec {
  gap: 35px;
  width: 95%;
}
.cs_7_heading {
    width: 100%;
}
.cs_7_content {
    width: 100%;
  opacity: 1;
}
.cs_7_color_bg {
  background: #051b8d;
  opacity: 100%;
}
.cs_8_heading {
    width: 104%;
  opacity: 1;
}
.cs_8_content {
    width: 100%;
  opacity: 1;
}
