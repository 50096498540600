.pas_main {
  margin: 100px 0px 75px 0px;
  width: 100%;
  height: 666px;
  background: url(../../assets/BearishPrivacyandSecurity/Rectangle\ 5548@2x.png);
  background-size: 100% 100%;
  font-family: serif;
  display: flex;
  gap: 65px;
  text-align: left;
}
.pas_1st_sec {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 76%;
  margin: 101px 0px 0px 52px;
}
.pas_1st_heading {
  width: 100%;
  opacity: 1;
  color: #000000;
  font: 701 40px/54px Source Serif Pro;
}
.pas_1st_content {
  width: 100%;
  color: #000000;
  opacity: 1;
  font: 25px/34px Source Serif Pro;
}
.pas_2nd_sec {
  display: flex;
  gap: 14px;
  width: 96%;
  height: 550px;
  margin: 0px 0px 74px 2.6%;

  font-family: serif;
  text-align: left;
  opacity: 1;
}
.pas_2nd_rt_sec {
  margin: 40px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 47%;
}
.pas_2nd_sec_img {
    width: 51%;
  height: 550px;
  opacity: 1;
}
.pas_2nd_sec_heading {
width: 100%;
}
.gap_20 {
  gap: 20px;
}
.pas_3rd_heading {
  margin: 0px 0px 70px 10.83%;
  width: 78.33%;
}
.pas_bottom {
  margin-bottom: 120px !important;
}
.pas_crd_img {
  width: 350px;
  height: 175px;
  margin: 38px 0px 0px -9px;
}
.pas_crd_2ndimg {
  width: 250px;
  height: 210px;
  margin: 15px 9px 0px 0px;
}
.pas_crd_3rdimg {
  width: 210px;
  height: 223px;
  margin: 10px 0px 0px;
}
.pas_5th_sec {
  width: 96%;
  height: 435px;
  margin: 0px 0px 100px 2.7%;
  font-family: serif;
  display: flex;
  gap: 30px;
  opacity: 1;
}
.pas_5th_sec_img {
    width: 44.23%;
  height: 435px;
  opacity: 1;
}
.pas_5th_sec_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  width: 50%;
}
.pas_5th_sec_heading {
  letter-spacing: 0.4px;
}
.pas_5th_sec_content {

}
.pas_6th_sec_heading {
  margin: 0px 0px 20px 2.7%;
  width: 77%;
  letter-spacing: 0.4px;
}
.pas_7th_section {
  width: 91.2%;
  height: 400px;
  display: flex;
  gap: 8.8%;
  font-family: serif;
  margin: 0px 0px 59px 38px;
}
.pas_7th_sect_img {
  width: 517px;
  height: 400px;
  opacity: 1;
}
.pas_7th_sect_c {
  width: 565px;
}
