.re_ai_main{
    width: 100%;
    height: 929px;
    margin: 100px 0px 27px 0px;
    background-color: rgba(28, 31, 95, 0.7);
}
.re_ai_content{
    width: 93%;
    margin: 30px 0px 0px 52px;
    font: normal 20px/28px source serif Pro;
    color: #ffffff;
    opacity: 1;

}
.re_ai_heading{
    /* width: 929px;
    height: 54px; */
 
}