.bs_pr_main{
    background-color: rgba(72, 108, 154, 0.75);
    gap: 54px;
    margin-bottom: 50px;

}
.bs_pr_1{
    margin-top: 82px;
    color: #ffffff;
    gap: 25px;

}
.bs_pr_1_heading{
    width: 94%;
    opacity: 100%;
}
.bs_pr_1_content{
    font: normal 20px/28px source serif Pro;
    display: flex;
    flex-direction: column;
    gap: 25px;
    
    text-align: left;
    opacity: 1;
 
    letter-spacing: 0px;
}

.bs_pr_2{
    width: 446px;
    height: 82px;
    margin: 0px 0px 30px 52px;
}
.bs_pr_3_sec{
    width: 84.18%;
    height: 2909px;
    margin: 0px 0px 28px 52px;
    font-family: serif;
    font: 18px/25px source serif pro;
    text-align: left;
}
.w_700{
    font-weight: 700;
}
.mt_22{
    margin-top: 22px;
}
.mt_10{
    margin-top: 5px;
}