.tranq_main {
  margin: 100px 0px 65px 0px;
  width: 100%;
  height: 666px;
  background: url(../../assets/BearishOSTranquilAI/Rectangle\ 5548@2x.png);
  font-family: serif;
  display: flex;
  background-size: 100% 100%;
  opacity: 1;
  gap: 18px;
}
.tranq_1_sec {
  width: 71.4%;
}
.tranq_1_sec_h {
  width: 86% !important;
  color: #e9edf2;
}
.tranq_1_sec_cont {
  width: 100%;
  color: #e9edf2;
}
.tranq_1_sec_img {
  width: 23.47%;
  height: 435px;
  margin: 116px 0px 0px 0px;
  opacity: 1;
}
.tranq_1_sec_img_ {
  width: 170%;
}
.tranq_2_sec {
  gap: 23px;
  width: 96.1%;
  margin-bottom: 70px;
  height: 500px !important;
}
.tranq_2_sec_img {
  width: 48.39%;
  height: 500px;
}
.tranq_2_sec_heading {
  width: 638px;
}
.tranq_2__mt {
  margin: 15px 0px;
}
.tranq_2_sec_content {
  width: 654px;

}
.tranq_3_sec {
  width: 96%;
  margin: 0px 0px 112px 2.6%;
  height: 550px;
  font-family: serif;
  display: flex;
  gap: 34px;
}
.tranq_3_sec_img {
  width: 51.5%;

  height: 550px;
  opacity: 1;
}
.tranq_3_sec_heading {
  width: 99%;
  margin: 40px 0px 30px 0px;
}
.new_wid_66{
  width: 92%;
}
.new_tr_a{
  width: 45.8%;
}
.tranq_3_sec_content {
  width: 100%;
  margin-bottom: 30px;
}
.tranq_3_sec_mg_bottom {
  margin-bottom: 24px !important;
}
.tranq_4_sec {
      margin: 0px 0px 40px 0px;
      display: flex;
      justify-content: center !important;
}
.tranq_4_sec_bottom {
  margin-bottom: 81px !important;
}

.tranq_1_img {
  width: 307px;
  height: 232px;
  opacity: 1;
  margin: 7px 7px 0px 0px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.tranq_2_img {
  width: 230px;
  height: 245px;
  margin: 4px 3px 0px 0px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.tranq_3_img {
  width: 146px;
  height: 232px;
  margin: 9px 4px 0px 0px;
  box-shadow: 0px 3px 6px #00000029;
border-radius: 10px;
}
.tranq_5_sec {
  width: 51.77%;
  margin-bottom: 40px;
  margin-left: 33px;
}
.tranq_6_sec {
  width: 96%;
  height: 575px;
  font-family: serif;
  background: url(../../assets/BearishOSTranquilAI/Group\ 4985@2x.png);
  margin: 0px 0px 76px 2.6%;
  background-size: 100% 100%;
  position: relative;
}
.tranq_6_1_img {
  width: 30%;
  height: 366px;
  position: absolute;
  top: 60px;
  left: 3.7%;

}
.tranq_6_2_img {
  width: 27.92%;
  height: 275px;
  top: 106px;
  position: absolute;
  left: 38%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.tranq_6_3_img {
  width: 23.61%;
  height: 232px;
  position: absolute;
  top: 128px;
  right: 4.6%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.tranq_6_1_cont {
  position: absolute;
  bottom: 74px;
  left: 3.7%;
  width: 33%;
}
.tranq_6_2_cont {
  position: absolute;
  bottom: 74px;
  left: 38.5%;
  width: 32%;
}
.tranq_6_3_cont {
  position: absolute;
  right: 3.1%;
  bottom: 74px;
  width: 27%;
}
.tranq_7_sec {
  width: 94%;
  margin: 0px 0px 86px 4.6%;
  height: 435px;
  font-family: serif;
  display: flex;
  gap: 31px;
}
.tranq_7_sec_img {
  width: 44.92%;
  height: 435px;
  opacity: 1;
}
.new_wid_tr_52{
  width: 52.57%;
}
.tranq_7_sec_heading {
  width: 95%;
}
.tranq_7_sec_content {
  width: 100%;
  margin: 30px 0px 30px 0px;
}
