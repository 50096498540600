.main{
    margin: 100px 0px 65px 0px;
    width: 100%;
    height: 666px;
    display: flex;
    font-family: serif;
}
.pr_mn_main{
    background:rgba(215, 224, 178, 0.75);
   
    gap: 25px;
}
.pr_mn_img{
    width: 25%;
}
.pr_mn_heading{
    width: 86%;
}
.pr_mn_content{
    width: 100%;
}
.pr_img{
    width: 144%;
    height: 100%;
    opacity: 1;
}
.pr_mn_1_heading{
    width: 92%;
}
.pr_mn_1_content{
    width: 100%;
}
.pr_mn_1_cont{
    width: 80%;
}
.pr_mn_1_mrgin_bt{
   margin-bottom: 110px;
}
.pr_mn_gap{
    gap: 56px;
    margin-bottom: 60px;
    width: 93.92%;
}
.pr_mn_3_heading{
    width: 96%;
}
.pr_mn_3_content{
    /* width: 635px;
    height: 75px; */
}
.pr_mn_3_img{
    width: 46%;
    height: 550px;
    opacity: 1;
}
.pr_mn_4_heading{
    width: 98%;
}
.pr_mn_4_content{
    width: 100%;
}
.pr_mn_4_cont{
    width: 95%;
    gap: 25px;
}
.pr_mn_5_sec{
    width: 60.97%;
    opacity: 1;
    margin: 0px 0px 40px 22.18%;
    text-align: center;
}
.pr_new_w{
    width: 50.53%;
}

.pr_mn_marg_bt{
    margin-bottom: 114px;
}
.pr_mn_7_heading{
    width: 96%;
    opacity: 1;
}
.pr_mn_7_content{
    width: 100%;
}
.pr_mn_7_content_new{
    width: 52%;
}
.pr_mn_rating_bg{
    background: #0A642C;
    opacity: 100%;
}
.pr_mn_8_heading{
    width: 97%;
    opacity: 1;
}
.pr_mn_8_content{
    width: 100%;
}