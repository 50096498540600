* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sub-menu {
  display: flex;
  padding: 34px;
  justify-content: space-between;
}

.bre-os {
  width: 57%;
  border-right: 3px solid #eae5e5;
  padding-right: 16px;
}
.bre-os p {
  padding: 15px 0px 24px 0px;
  font: 600 15px/21px source serif Pro;
}
.bre-os a {
  font:600 15px/21px source serif Pro;
}
.bre-os h2 {
  font: 800 20px/28px source serif Pro;
}
.core-feature {
  width: 38%;
}
.core-feature ul {
  font: 15px/21px source serif Pro;
}

.fo_w_600{
  font-weight: 600 !important;
}