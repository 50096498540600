.p_main {
  width: 898px;
  height: 108px;
  opacity: 1;
}
.new_pricing_page_main {
  margin: 100px 0px 26px 0px;
  background: rgba(72, 108, 154, 0.75);
}
.new_pricing_ {
  font: 700 40px / 54px source serif Pro;
  color: #ffffff;
  padding: 23px 54px;
}
.new_pricing_crd {
  display: flex;
  margin: 5px 0px 50px 20px;
  flex-wrap: wrap;
  width: 95.9%;
  gap: 15px;
  justify-content: center;
}
.pricing_card {
  width: 19.08%;
  height: 825px;
  border: 3px solid #e9edf2;
  border-radius: 5px;
}
.best_val {
  margin-left: 27.45%;
  font: 800 15px / 21px source serif Pro;
  color: #ff0000;
}
.best_pric_content {
  font: 300 15px / 21px source serif Pro;
  text-align: left;
  padding: 20px 7px;
}
.best_pric_ {
  font: 35px / 48px source serif Pro;
  padding: 0px 30%;
  text-align: left;
}
.best_pric_per_m {
  padding: 3px 0px 32px 24%;
  font: 15px / 21px source serif Pro;
}

.best_pric_per_ {
  margin: 0px 0px 0px 13px;
  display: flex;
  gap: 3.96px;
  padding-bottom: 15.5px;
}

.best_pr_btn {
  width: 86%;
  margin-left: 7.2%;
  text-align: center;
  color: #ffffff;
  background: #3977cb 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf2;
  border-radius: 5px;
  opacity: 1;
  padding: 7px 50px;
  font: 15px / 21px source serif Pro;
  cursor: pointer;
}

.best_pricing_sig {
  text-align: left;
  width: 20px;
  height: 20px;
}
.font_pricing_c {
  font: 15px / 25px source serif Pro;
}

.best_heading {
  width: 101.5%;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font: 600 25px / 34px source serif pro;
  color: #ffffff;
  padding: 8px 0px;
  margin: -2px -2px;
}

.light_pricing_crd {
  display: flex;
  gap: 2.6%;
  width: 92.75%;
  margin-left: 3.6%;
  margin-bottom: 67px;
  flex-wrap: wrap;
  justify-content: center;
}
.new_img_pricing{
    width: 89.25%;
    height: 43.61%;
    margin: 54px 5% 29px;
}
.mb_25px_crd{
  margin-bottom: 25px;
}
.mb_50px_crd{
margin-bottom: 50px !important;
}
.mt_32px_top{
    margin-top: 32px;
}
.new_bk_color_gr{
    background: #1C5F45 !important;
    border: 3px solid #3977CB !important;
    opacity: 1;
}

.ht_169 {
  height: 169px;
}
.mt_bt_101 {
  margin-bottom: 101px;
}
.p_r_sec {
  width: 92.75%;
  height: 250px;
  margin: 0px 0px 40px 3.6%;
  border: 3px solid #e9edf2;
  border-radius: 5px;
}
.p_r_sec_head {
  height: 75px;
  border: 3px solid #3977cb;
  border-radius: 5px;
  background: #526f96;
  margin-left: -2px;
}
.p_r_sec_cont {
  text-align: center;
  margin: 11px 0px;
  font: 600 40px/54px source serif Pro;
  color: #ffffff;
  opacity: 1;
}

.pricing_sec_card_top {
  font: 600 25px / 34px source serif Pro;
  color: #ffffff;
  text-align: center;
  padding: 8px 0px;
  border-radius: 5px;
  width: 101%;
  margin: -2px 0px 0px -2px;
}

.werty_price {
  font: 15px / 21px soure serif Pro;
  padding: 20px 0px 22px 16px;
}

.werty_price_num {
  font: 35px / 48px source serif Pro;
  padding: 0px 0px 3px 40%;
}

.per_per_pri {
  text-align: center;
  font: 15px / 21px source serif Pro;
  margin-bottom: 22px;
}

.best_pric_per_sign {
  margin: 0px 0px 15px 6%;
  display: flex;
  gap: 3.96px;
}

.add_btn_prc{
    width: 53.75%;
    margin: 0px 0px 16px 23.25%;
    text-align: center;
    padding: 7px 0px;
    background: #3977CB 0% 0% no-repeat padding-box;
    border: 1px solid #E9EDF2;
    border-radius: 5px;
    opacity: 1;
    color: #FFFFFF;
    font: 600 15px / 21px source serif Pro;
}

.sm_prc_content{
    font: 10px / 13px source serif Pro;
    text-align: center;
    padding: 0% 10%;
}
.pricing_lig_data {
  width: 31.57%;
  height: 525px;
  border: 3px solid #e9edf2;
  border-radius: 5px;
  opacity: 1;
}

.light_pri {
  width: 92.75%;
  background: #771f0a;
  border: 3px solid #771f0a;
  border-radius: 5px;
  opacity: 1;
  margin-left: 3.6%;
  margin-bottom: 40px;
  text-align: center;
  font: 600 40px / 54px source serif Pro;
  color: #ffffff;
  padding: 10px 0px;
}

.p_r_cont_sec {
  width: 72.71%;
  margin: 18px 0px 0px 14.7%;
  text-align: center;
  font: 25px/34px source serif Pro;
}
.p_r_spn {
  color: red;
  font-size: 50px;
  font-weight: 600;
  line-height: 68px;
}
.p_r_2_cont {
  width: 245px;
  height: 34px;
  margin: 0px 0px 0px 36px;
  font: 20px/34px source serif Pro;
}

.position_ {
  position: relative;
  height: 262px;
  left: 36px;
}
.p_r_2_head {
  width: 474px;
  height: 108px;
  font: 40px/54px source serif Pro;
  position: absolute;
  top: 50px;
}
.p_r_3_head {
  width: 558px;
  height: 102px;
  font: 25px/34px source serif Pro;
  position: absolute;
  top: 5px;
  right: 36px;
}

.p_r_sec_4_div {
  display: flex;
  gap: 71px;
  margin: 0px 0px 0px 36px;
  width: 1267px;
  height: 985px;
}
.p_r_sec_4_ {
  width: 375px;
  height: 950px;
  border: 3px solid #e9edf2;
  border-radius: 5px;
  opacity: 1;
}
.p_s_4_crd {
  width: 375px;
  height: 75px;
  background: #3977cb 0% 0% no-repeat padding-box;
  border: 3px solid #3977cb;
  border-radius: 5px;
  opacity: 1;
  margin: -2px 0px 0px -2px;
}

.mt_top_35 {
  margin: 35px 0px 0px 0px;
}
.p_r_5_content {
  height: 54px;
  margin: 10px 0px 0px 0px;
  font: 501 40px/54px source serif Pro;
  color: #ffffff;
  text-align: center;
}

.p_r_2_crd {
  background: #72ffce 0% 0% no-repeat padding-box;
  border: 3px solid #72ffce;
  border-radius: 5px;
}
.p_r_3_crd {
  background: #00712b 0% 0% no-repeat padding-box;
  border: 3px solid #00712b;
  border-radius: 5px;
}

.color_b {
  color: #000000;
}

.p_r_ht_625 {
  height: 625px;
}

.p_r_tp {
  height: 30px;
  background: #ffba00 0% 0% no-repeat padding-box;
  border: 3px solid #ffba00;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  text-align: center;
  width: 175px;
  font: italic 25px/34px source serif Pro;
  margin: 0px 0px 0px 1px;
}

.p_r_it {
  width: 82.43%;
  margin: 30px 0px 53px 7.7%;
  text-align: center;
  font: italic 25px/34px source serif Pro;
}

.p_r_in_div {
  margin: 24px 0px 5px 87.93px;
  display: flex;
  gap: 11.07px;
}

.p_r_1_n {
  width: 69px;
  height: 41px;
  /* font-size: 30px; */
  font: 30px/41px source serif Pro;

  /* line-height: 41px; */
  text-align: left;
  position: relative;
  margin-top: 17px;
}

.p_r_2_n {
  width: 115px;
  height: 69px;
  font: 50px/69px source serif Pro;
  text-align: left;
}

.p_r_2_s_ {
  margin: 1px 0px 0px -68px;
  position: absolute;
  width: 107%;
  height: 100%;
}

.p_r_4 {
  margin: 0px 0px 40px 77px;
  font: 25px/34px source serif Pro;
}
.p_r_5 {
  width: 292px;
  height: 56px;
  margin: 0px 0px 40px 40px;
  text-align: center;
  font: italic 20px/28px source serif Pro;
}

.p_r_ic_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.p_r_ic_con {
  margin: 0px 0px 0px 27px;
  display: flex;
}

.p_r_ic_cntent {
  text-align: center;
  font: 20px/28px source serif Pro;
  width: 262px;
  height: 28px;
}
.p_r_ico_ {
  text-align: left;
  width: 20px;
  margin: 5px 6px 0px 0px;
  height: 20px;
}

.p_r_se {
  width: 210px;
  height: 28px;
  margin: 24px 0px 0px 82px;
  color: #2700ff;
  font: 500 20px/28px source serif Pro;
}
.p_r_2_crd_content {
  margin: 40px 0px 0px 89.97px;
}

.mt_bt_29 {
  margin-bottom: 29px;
}
.mt_bt_48 {
  margin-bottom: 48px;
}

.mt_pr_se {
  margin: 16px 0px 0px 82px;
}

.width_84 {
  width: 84px;
}

.left_68 {
  left: 68px;
}
.m_pr_ {
  margin: 0px 0px 2px 77px;
}
.m_b_minimum {
  display: flex;
  justify-content: center;
  font: 15px/21px source serif Pro;
  margin-bottom: 6px;
}
.ico_pr {
  width: 67%;
  height: 67%;
  opacity: 1;
}

.new_list_sec{
  width: 93.70%;
  margin-left: 3.6%;
  border: 3px solid #E9EDF2;
  border-bottom: 2px solid #E9EDF2;
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 30px;
}

.new_th{
  display: flex;
  background: #FFC600 0% 0% no-repeat padding-box;
  border: 3px solid #FFC600;
  border-radius: 5px;
  margin: -2px -2px 0px;
}
.row {
  display: flex;
}
.thead {
  display: flex;
  background: rgba(255, 198, 0, 0.5);
  border: 3px solid rgb(241 213 117 / 50%);
  border-bottom: none;
  border-radius: 5px;
  margin: -2px -2px 0px;
}
.thead_sec_color{
  background: rgba(57, 119, 203, 0.5);
    border: 3px solid rgb(135 169 215 / 50%);
    border-bottom: none;
}

.thead_third_color {
  background: rgba(255, 203, 203, 0.5);
  border: 3px solid rgb(239 210 210 / 50%);
  border-bottom: none;
}

.thead_4th_color {
  background: rgba(89, 175, 135, 0.5);
  border: 3px solid rgb(121 199 163 / 50%);
  border-bottom: none;
}

.thead_5th_color {
  background: rgba(128, 51, 125, 0.5);
  border: 3px solid rgb(183 115 180 / 50%);
  border-bottom: none;
}

.thead_6th_color {
  background: rgba(255, 0, 0, 0.5);
  border: 3px solid rgb(222 108 108 / 50%);
  border-bottom: none;
}
.thead_7th_color {
  background: rgba(57, 119, 203, 0.5);
  border: 3px solid rgb(132 167 215 / 50%);
  border-bottom: none;
}

.thead_8th_color {
  background: rgba(213, 145, 104, 0.5);
  border: 3px solid rgb(204 161 136 / 50%);
  border-bottom: none;
}
.thead_9th_color {
  background: rgba(175, 104, 213, 0.5);
  border: 3px solid rgb(190 146 214 / 50%);
  border-bottom: none;
}
.thead_10th_color {
  background: rgba(0, 216, 255, 0.5);
  border: 3px solid rgb(140 217 231 / 50%);
  border-bottom: none;
}
.col {
  flex: 1;
  font: 700 20px / 28px source serif Pro;
  padding: 1.26% 0%;
  text-align: center;
}
.new_font_size {
  font: 700 19px / 28px source serif Pro;
}
.col1 {
  flex: 1;
  padding: 27px 0px;
  font: 701 15px / 21px source serif Pro;
  border-right: 2px solid #E9EDF2;
  border-bottom: 2px solid #E9EDF2;
  text-align: center;
}
.new_sign_ic{
  width: 20px;
  height: 20px;
  opacity: 1;
}
.col1:last-child {
  border-right: none; /* Remove right border from the last column in each row */
 /* Remove right border from the last column in each row */
}
 .row:last-child {
  border-bottom: none; /* Remove right border from the last column in each row */
}
.col:first-child {
  flex: 1.2;
  padding: 1.26% 20px !important;
  text-align: left; /* Set the width of the first column to be 20% wider than other columns */
}
.col1:first-child {
  flex: 1.2;
  padding: 27px 18px !important;
  text-align: left; /* Set the width of the first column to be 20% wider than other columns */
}