.sim_main {
  margin: 100px 0px 75px 0px;
  width: 100%;
  height: 666px;
  background: url(../../assets/BearishSimplify/Rectangle\ 5548@2x.png);
  display: flex;
  gap: 34px;
  font-family: serif;
  opacity: 1;
}
.sim_img {
  width: 24.3%;

  height: 443px;
  margin: 112px 0px 0px 0px;
  opacity: 1;
  overflow: hidden;
}
.sim_img_w {
  width: 160%;
  height: 100%;
  border-radius: 10px;
}
.sim_cont {
  margin: 101px 0px 0px 3.8%;
  text-align: left;
  width: 69.48%;
}
.sim_m_heading {
  width: 100%;
  color: #ffffff;
  margin: 0px 0px 40px 0px;
  opacity: 1;
  font: 701 40px/54px Source Serif Pro;
}
.sim_m_content {
  width: 99%;
  margin: 0px 0px 74px 0px;
  opacity: 1;
  color: #ffffff;
  font: 25px/34px Source Serif Pro;
}
.sim_second_sec {
  width: 96%;
  height: 550px;
  margin: 0px 0px 35px 33px;
  font-family: serif;
  opacity: 1;
  display: flex;
  gap: 21px;
}
.sim_img_rt {
  width: 51.53%;
  height: 550px;
  opacity: 1;
}
.sec_content_sim {
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 1;
  text-align: left;
  width: 46.41%;
}
.sec_sim_header {
  width: 98%;
}
.sec_sim_content_ {
  width: 100%;
}
.a_lnk_sim {
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.lnk_sim {
  display: flex;
  gap: 30px;
}
.lk {
  margin-top: 5px;
}
.thrd_sec_sim {
  display: flex;
  gap: 20px;
  font-family: serif;
  height: 540px;
  margin-bottom: 97px;
  opacity: 1;
}
.thrd_simp_imgs {
  width: 48.6%;
  height: 540px;
  opacity: 1;
  background: url(../../assets/BearishSimplify/Group\ 4972@2x.png);
  background-size: 100% 100%;
  border-radius: 25px;
}
.thrd_sec_1st_img {
  width: 65%;
  height: 214px;
  top: 30px;
  left: 3.4%;
  opacity: 1;
}

.bd_r_3_box_sh {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
}

.thrd_sec_2nd_img {
  width: 30%;
  height: 250px;
  top: 272px;
  left: 63%;
  opacity: 1;
}
.thrd_sec_3rd_img {
  width: 46.3%;
  height: 232px;
  top: 152px;
  left: 30%;
  opacity: 1;
}
.thrd_sec_4th_img {
  width: 26.2%;
  height: 276px;
  top: 248px;
  left: 12.5%;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}
.sm_4th_header {
  width: 100% !important;
}
.sm_4th_cont {
  width: 100%;
}
.sm_4th_sec {
  display: flex;
  flex-direction: column;
  gap: 7.4%;
  width: 51.3%;
  opacity: 1;
}
.sim_4th_ico_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 1;
}
.sm_4th_lnk {
  width: 100%;
}
.sm_th_lnk {
  width: 585px;
  height: 50px;
}
.sm_h_lnk {
  width: 556px;
  height: 50px;
}
.sm_hr_lnk {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.sm_mg_bottom {
  margin-bottom: 91px !important;
}
.sim_wi {
  margin: 0px 0px 30px 2.6%;
  width: 48%;
}
.blnk_back {
  width: 96%;
  height: 550px;
  margin: 0px 0px 110px 2.6%;
  background: url(../../assets/BearishSimplify/Group\ 4985@2x.png);
  background-size: 100% 100%;
  opacity: 100%;
  border-radius: 25px;
}
.sim_6th_sec {
  display: flex;
  width: 96%;
  height: 435px;
  margin: 0px 0px 153px 2.6%;
  gap: 4%;
  font-family: serif;
  opacity: 1;
}
.sim_6th_img {
  width: 44%;
  height: 435px;
  opacity: 1;
}
.sim_6th_cont {
  width: 81%;
  margin-bottom: 25px;
}
.sim_6th_content {
  
}
.new_sim_dat {
  width: 52% !important;
}
.sim_7th_heading {
  width: 68.34%;
  margin: 0px 0px 50px 2.6%;
}
.sim_8th_heading {
  width: 96%;
  height: 530px;
  background: red;
  margin: 0px 0px 109px 2.6%;
  font-family: serif;
  gap: 40px;
  display: flex;
}
.sim_8th_sec {
  display: flex;
  gap: 40px;
  font-family: serif;
  opacity: 1;
  margin: 0px 0px 109px 2.6%;
}
.sm_8th_sec_img {
  width: 49%;
  height: 525px;
  margin-top: 5px;
  opacity: 1;
}
.sm_8th_content {
  width: 43.19%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  opacity: 1;
}
.md_image {
  width: 322px;
  height: 112px;
  margin: 63px 0px 0px -6px;
}
.md_thrd_image {
  width: 300px;
  height: 213px;
  padding: 17px 0px 0px 0px;
}
.sm_8th_content_section {
  display: flex;
  gap: 15px;
  height: 50px;
}
.wi_524 {
  width: 524px;
}
.sm_9th_sec {
  width: 96%;
  height: 550px;
  margin: 0px 0px 101px 2.7%;
  font-family: serif;
  opacity: 1;
  display: flex;
  gap: 30px;
}
.sm_9th_img {
  width: 48%;
  height: 550px;
  opacity: 1;
}
.sm_9th_heading {
  width: 100%;

}
.sm_9th_content {
   width: 100%;

}
.sim_9th_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 49%;
}
.sim_9th_ico_cont {
  display: flex;
  gap: 20px;
}
.sim_9th_lnk {
  font-size: 20px;
  color: #000000;
  line-height: 28px;
}
