.int_main {
  margin-top: 100px;
}
.int_head_par {
  width: 68.15%;
  padding: 97px 0px 0px 50px;
  font-family: serif;
  text-align: left;
}
.int_heading {
  width: 100%;
  height: 207px;
  color: #000000;
  opacity: 100%;
  font: 700 50px/69px Source Serif Pro;
}
.int_contnt {
  width: 691px;
  height: 102px;
  margin-top: 30px;
  color: #000000;
  font: 300 25px/34px Source Serif Pro;
}
.mt_50 {
  margin-top: 50px;
}
.container {
  width: 1366px;
  margin: 0 auto;
}
.containernew {
  width: 1300px;
  margin: 0 auto;
}
.int_img_content {
  border-radius: 25px;
  width: 95%;
  margin: 2% 2%;
  background-size: 100% 100% !important;
  height: 270px;
  background: url(../../assets/BearishOSinteligent/Group\ 4940@2x.png);
  /* background-size: cover; */
}

.int_cont {
  width: 98.43%;
  height: 186px;
  padding: 42px 46px;
}
.int_p_tag {
  color: #fff;

  text-align: center;
  margin: 0px 0px;
  font: normal 600 45px/62px Source Serif Pro;
}
.int_middle_div {
  margin-top: 88.16px;
  margin-bottom: 29.88px;
  display: flex;
  gap: 50px;
  width: 95.16%;
}
.int_back_ground {
  height: 414px;

  width: 34%;
  margin: 36.84px 0px 0px 63px;
  background-size: cover;
  background: url(../../assets/Screenshot\ 2023-12-30\ at\ 2.24.50\ PM.png);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  background-size: 100% 100%;
}
.int_img_email {
  margin: -43px 6px 0px 77.38%;
  /* width: 123.69px; */
  height: 123.69px;
  opacity: 1;
}
.int_img_out {
  height: 121.22px;
  margin: 14% -16%;
  opacity: 1;
}
.int_img_t {
  width: 114.41px;
  height: 114.41px;
  margin: 22px 0px 0px 78%;
  opacity: 1;
}
.int_rt_content {
  margin: 145px 0px 0px;

  text-align: left;
  width: 804px;
  height: 198px;

  opacity: 1;

  font: 600 48px/66px Source Serif Pro;
}
.intr {
  width: 100%;
  height: 666px;
  background: rgba(114, 255, 206, 0.5);
}
.int_red {
  color: #ff0e0e;
}
.int_blue {
  color: #2700ff;
}
.int_sec_middle_content {
  width: 95.14%;
  height: 650px;
  display: flex;
  margin: 87px 0px 0px 2%;
  border-radius: 10px;
  box-shadow: 8px 10px 7px -1px rgb(0 0 0 / 16%);
  background: url(../../assets/BearishOSinteligent/Group\ 4964.png);
}
.int_mid_rt_cont {
  width: 716px;
  height: 264px;
  margin: 172px 0px 0px 30px;
  text-align: left;
  color: #ffffff;
  font-family: Source Serif Pro Black;
  opacity: 1;
  font: 700 48px/66px Source Serif Pro;
}
.int_lt_back_img {
  width: 30.45%;
  height: 414px;
  margin: 97px 0px 0px 0px;
  background: url(../../assets/Screenshot\ 2024-01-01\ at\ 4.14.50\ PM.png);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}
.int_img_up {
  margin: -76px 0px 0px 191px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  width: 80%;
}
.int_img_sid {
  margin: -92px 0px 0px 191px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  width: 31%;
}

.int_super {
  width: 80.84%;
  height: auto;
  margin: 10% 10% 0px;

  text-align: center;

  opacity: 100%;
  color: #000000;

  font: 800 35px/48px Source Serif Pro;
}
.int_testi {
  width: 81.69%;
  margin: 80px 0px 0px 141px;
  display: flex;
  gap: 134px;
}
.int_fi_div {
  width: 376px;
  height: 384px;
}
.int_sec_div {
  width: 606px;
  height: 384px;
}
.int_img_de {
  margin: 30px 0px 0px 105px;
  font-size: 25px;
  font-family: serif;
}
.int_img_det {
  text-align: center;
  font-size: 25px;
  margin: 30px 0px 0px 162px;
  font-family: serif;
}
.img_int {
  margin: -344px 0px 0px 457px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  width: 28%;
}
.img_int_de {
  margin: 20px 0px 0px 525px;
  width: 294px;
  font-size: 25px;
  font-family: serif;
}

.ti_div {
  margin: 80px 0px 0px 33px;
  width: 93.33%;
  height: 666px;
  opacity: 1;
  display: flex;
  gap: 50px;
  font-family: serif;
}
.ti_img {
  width: 50%;
  height: 666px;
  background: url(../../assets/BearishOSinteligent/Group\ 4965.png);
  opacity: 1;
  border-radius: 25px;
}
.img_ti_div {
  width: 81%;
  height: 558px;
  margin: 11% 0px 0px 10%;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}
.ti_head {
  margin: 134px 0px 0px 0px;
  width: 443px;

  font: 800 48px/66px Source Serif Pro;
  color: #000000;
  opacity: 1;
}
.ti {
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 30px;
  width: 50%;
}
.p_content_sec {
  width: 575px;
  height: 102px;
  /* font-size: 25px;
  line-height: 34px; */
  padding: 0px 0px;
  font: 300 25px/34px Source Serif Pro;
}
.p_content {
  width: 548px;

  height: 102px;
  font: 300 25px/34px Source Serif Pro;
}
.mid_sec {
  margin: 95px 25px 0px;
  background: url(../../assets/BearishOSinteligent/Group\ 4963@2x.png);
  height: 700px;
  background-size: cover;
  width: 96.33%;
  box-shadow: 10px 10px 10px #00000029;
  border-radius: 18px;
  display: flex;
  border-radius: 25px;
  text-align: left;
  font-family: serif;
  opacity: 1;
  gap: 150px;
}
.mid_img {
  width: 433px;
  height: 595px;
  margin-top: 53px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  opacity: 1;
}
.mid_content {
  width: 677px;
}

.mid_cont_head {
  width: 613px;
  margin: 185px 0px 0px 64px;
  height: 198px;

  color: #ffffff;
  font: 700 48px/66px Source Serif Pro;
}
.mid_cont {
  margin: 30px 0px 0px 64px;
  width: 576px;
  height: 102px;
  color: #ffffff;
  font: 300 25px/34px Source Serif Pro;
}
.say_it {
  width: 79.64%;
  margin: 96px 0px 0px 10%;

  text-align: center;
  color: #000000;

  font: 800 40px/54px Source Serif Pro;
}
.say_cont {
  width: 70%;
  margin: 30px 0px 0px 14%;
  text-align: center;
  font: 300 25px/34px Source Serif Pro;
  color: #000000;
}

.img_3 {
  width: 96%;
  height: 475px;
  margin: 30px 0px 0px 2%;
  border-radius: 25px;
  display: flex;
  gap: 50px;
  background: url(../../assets/BearishOSinteligent/Rectangle\ 5546.png);
}
.img_3_f {
  width: 24.71%;
  height: 400px;
  margin: 20px 0px 0px 8%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}
.img_f {
  width: 24.71%;
  height: 400px;
  margin: 20px 0px 0px 0px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}
.int_email {
  width: 91.28%;
  height: 690px;
  margin: 50px 0px 0px 5.4%;
  font-family: serif;
  display: flex;
  gap: 13px;
}
.int_email_img {
  width: 41.43%;
  height: 690px;
  background: url(../../assets/BearishOSinteligent/Group\ 4966.png);
  opacity: 1;
  background-size: cover;
  box-shadow: 10px 10px 10px #00000029;
  border-radius: 10px;
}
.email_content {
  width: 100%;

  text-align: left;

  color: #000000;
  opacity: 100%;
  margin-top: 34px;
  font: 300 25px/34px Source Serif Pro;
}
.email_heading {
  width: 93%;

  margin: 36px 0px 0px 0px;
  font: 700 50px/69px Source Serif Pro;
}
.img_em_int {
  width: 82%;
  height: 595px;
  margin: 48px 9%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}
.img_wi_cont {
  width: 96%;
  height: 270px;
  margin: 127px 0px 113px 2.4%;
  background: url(../../assets/BearishOSinteligent/Group\ 4940-1@2x.png);
  background-size: cover;
  border-radius: 25px;
  position: relative;
}

.bg_content_ {
  width: 93%;
  height: 186px;
  left: 2.4%;
  top: 42px;
  text-align: center;
  font: 700 45px/62px source serif Pro;
  color: #ffffff;
}

.out_img {
  width: 121.22px;
  height: 121.22px;
  margin: -50px 0px 0px -38px;
  opacity: 1;
}
.ico_user {
  width: 123.69px;
  height: 123.69px;
  top: -55px;
  left: 93%;
}
.nex_icon {
  left: 86%;
  top: 200px;
  width: 114.41px;
  opacity: 1;
  height: 114.41px;
}
.sa_icon {
  width: 114.41px;
  height: 114.41px;
  top: 200px;
  left: 7.54%;
}
.sa_div_ico {
  display: flex;
  justify-content: space-between;
}
.foo_ter_container {
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1920px) {
  .hamburger-menu {
    display: none;
  }
  .m_w_simpler {
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 1440px) {
  .hamburger-menu {
    display: none;
  }
  .m_w_simpler {
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .col1 {
    font: 701 12px / 21px source serif Pro !important;
  }
  .col {
    font: 700 17px / 28px source serif Pro !important;
  }
  .new_sign_ic {
    width: 15px !important;
    height: 15px !important;
  }

  .tp_h {
    width: 55% !important;
  }

  .bs_pr_1_content {
    font: normal 16px / 28px source serif Pro !important;
    gap: 20px !important;
  }

  .bs_pr_3_sec {
    font: 13px / 25px source serif pro !important;
  }
  .pricing_sec_card_top {
    font: 600 22px / 29px source serif Pro !important;
  }
  .werty_price {
    font: 11px / 21px soure serif Pro !important;
  }
  .font_pricing_c {
    font: 12px / 25px source serif Pro !important;
  }
  .werty_price_num {
    font: 29px / 45px source serif Pro !important;
  }

  .per_per_pri {
    font: 13px / 20px source serif Pro !important;
  }
  .p_r_cont_sec {
    font: 19px / 34px source serif Pro !important;
  }

  .light_pri {
    font: 600 35px / 54px source serif Pro !important;
  }
  .best_val {
    margin-left: 46.45% !important;
  }
  .pricing_card {
    width: 30.08% !important;
  }
  .new_sim_dat {
    gap: 24px !important;
  }
  ._rate_content {
    font: 16px / 23px source serif Pro !important;
  }
  .mrk_8_heading {
    width: unset !important;
  }
  .top_heading {
    font: normal 701 27px / 54px Source Serif Pro !important;
  }
  .top_content {
    font: 17px / 34px Source Serif Pro !important;
  }
  .tranq_2_sec_heading {
    width: 106% !important;
  }
  .tranq_2_sec_content {
    width: unset !important;
  }
  .sim_9th_ico_cont {
    gap: 13px !important;
  }
  .sim_9th_lnk {
    font-size: 15px !important;
  }
  .tranq_6_2_cont {
    bottom: 50px !important;
    width: 29% !important;
  }
  .tranq_6_3_cont {
    bottom: 53px !important;
  }
  .pas_3rd_heading {
    margin: 0px 0px 70px 7.83% !important;
    width: 86.33% !important;
  }
  .sim_4th_ico_cont {
    gap: 21px !important;
  }
  .beba_4rd_section {
    gap: 13px !important;
  }
  .pas_1st_heading {
    font: 701 29px/46px Source Serif Pro !important;
  }
  .pas_1st_content {
    font: 21px/30px Source Serif Pro !important;
  }
  .beba_5th_section_link {
    font-size: 13px !important;
  }
  .beba_6_heading {
    /* margin: 0px 0px 30px 6.7% !important;
  width: 94.52% !important; */
  }
  .rep_heading {
    font: 700 28px/46px Source Serif Pro !important;
  }
  .rep_content {
    font: 19px/33px Source Serif Pro !important;
  }
  .sim_m_heading {
    font: 701 28px/50px Source Serif Pro !important;
  }
  .sim_m_content {
    font: 19px/31px Source Serif Pro !important;
  }
  .sec_sim_header {
    width: 100% !important;
  }
  .rs_first_heading {
    font: 701 28px/52px Source Serif Pro !important;
  }
  .rs_fsec_content {
    font: 17px/33px Source Serif Pro !important;
  }
  .fs_18_h_25_left_blk {
    font: 15px/22px Source Serif Pro !important;
  }
  .heading_30_41_left_blk {
    font: normal 700 24px/41px Source Serif Pro !important;
  }
  .rs_sec_content {
    font: 13px/25px Source Serif Pro !important;
  }
  .rs_sec_heading {
    font: 701 26px/41px Source Serif Pro !important;
  }
  .third_sec_heading {
    font: 800 26px/41px Source Serif Pro !important;
  }
  .vt_sec_heading {
    font: 500 33px/44px Source Serif Pro !important;
  }
  .vt_rt_heading {
    font: 700 36px/62px Source Serif Pro !important;
  }
  .vt_sec_content {
    font: 300 18px/34px Source Serif Pro !important;
  }
  .vt_rt_content {
    font: 400 17px/31px Source Serif Pro !important;
  }
  .vt_sec_ro_ele {
    font: 700 20px/38px Source Serif Pro !important;
  }
  .vt_cont_sec_1 {
    font: 300 18px/34px Source Serif Pro !important;
  }
  .vt_cont_sec_2 {
    font: 300 18px/34px Source Serif Pro !important;
  }
  .vt_crd {
    width: 43% !important;
  }
  .meet_heading {
    font: 700 25px/37px Source Serif Pro !important;
  }
  .meet_p {
    font: 400 15px/18px Source Serif Pro !important;
  }
  .vt_par_crd_div {
    gap: 77px 77px !important;
  }
  .vt_rating_content {
    font: 800 25px/48px Source Serif Pro !important;
  }
  .vt_suff_header {
    font: 701 25px/37px Source Serif Pro !important;
  }
  .ft_18_l_ht_25 {
    font: 400 13px/24px Source Serif Pro !important;
  }
  .vt_link_tag {
    font: 601 18px/27px Source Serif Pro !important;
  }
  .rel_header {
    font: 700 24px/39px Source Serif Pro !important;
  }
  .rel_content {
    font: 400 18px/29px Source Serif Pro !important;
  }
  .rt_rating_heading {
    font: 700 24px/49px Source Serif Pro !important;
  }
  .crd_relation {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px 0px 100px 3%;
  }
  .rt_card_h {
    width: 45% !important;
  }
  .rt_crd_main_m {
    margin: 75px 0px 0px 3% !important;
    gap: 26px !important;
  }
  .rt_left_content_heading {
    font: 700 25px/39px Source Serif Pro !important;
  }
  .rt_left_content_cont {
    font: 16px/23px Source Serif Pro !important;
  }
  .left_icon_content {
    font: 16px/23px Source Serif Pro !important;
  }
  .bld_up_content_bd {
    top: 17% !important;
    font: 601 29px/49px source serif Pro !important;
  }
  .rt_cd_heading {
    width: 67% !important;
    font: 700 24px/41px Source Serif Pro !important;
  }
  .lt_headding {
    font: italic 30px/48px source serif Pro !important;
  }
  .cent_1_sec_heading {
    font: 700 35px/60px Source Serif Pro !important;
  }
  .cent_1_sec_content {
    font: 400 17px/34px Source Serif Pro !important;
  }
  .cent_2_sec_content {
    font: 700 32px/56px Source Serif Pro !important;
  }
  .cent_3_sec_heading {
    font: 600 34px/58px Source Serif Pro !important;
  }
  .cent_3_sec_content {
    font: 18px/31px Source Serif Pro !important;
    width: 99%;
  }
  .cent_5th_sec_heading {
    font: 700 24px/43px Source Serif Pro !important;
  }
  .cent_3_sec {
    gap: 90px;
  }
  .cent_rtig {
    margin: 12% 0px 0px 3% !important;
    gap: 23px !important;
  }
  .rt_text {
    width: 88%;
    font: 15px/20px Source Serif Pro !important;
  }
  .rt_crd_detail {
    /* margin: 0px 0px 113px 6% !important; */
    gap: 70px !important;
  }
  .cent_4_sec {
    height: 62px;
    font: 600 42px/58px Source Serif Pro !important;
  }
  .crd_detail_h {
    width: 44%;
  }
  .rt_crd_detail {
    margin: 0px 0px 113px 33px;
    gap: 70px 42px;
  }
  .intr {
    width: 100%;
  }
  .int_heading {
    font: 700 35px/54px Source Serif Pro;
    height: unset;
  }
  .int_contnt {
    font: 300 22px/30px Source Serif Pro;
  }
  .int_img_content {
    width: 95%;
    margin: 2% 2%;
    background-size: 100% 100%;
  }
  .int_p_tag {
    font: normal 600 29px/59px Source Serif Pro;
  }
  .int_img_out {
    margin: 20% -25%;
  }
  .int_rt_content {
    width: 58.85%;
    font: 600 30px/54px Source Serif Pro;
    margin: 87px 0px 0px 2%;
  }

  .int_mid_rt_cont {
    width: 52%;
    font: 700 33px/56px Source Serif Pro;
  }
  .int_lt_back_img {
    width: 34.45%;
    height: 56%;
    background-size: 100% 100%;
  }
  .int_img_up {
    margin: -60px 0px 0px 44%;
    height: 88%;
  }
  .int_img_sid {
    margin: -24% 0px 0px 42%;
  }
  .int_super {
    font: 800 25px/40px Source Serif Pro;
  }
  .int_testi {
    gap: 5%;
  }
  .int_img_de {
    margin: 20px 0px 0px 10%;
    font: 20px/34px source serif Pro;
  }
  .img_int {
    position: absolute;
    margin: -30% 0px 0px 36%;
    width: 27%;
    height: 86%;
  }
  .int_img_det {
    text-align: center;
    font: 18px/109px source serif Pro;
    margin: -6% 0px 0px 30%;
    position: relative;
  }
  .img_int_de {
    margin: 0px 0px 0px 41%;
    /* width: 294px; */
    font-size: 18px;
    font-family: serif;
  }
  .int_sec_div {
    width: 100%;
    height: 75%;
  }
  .int_fi_div {
    width: 90%;
    height: 75%;
  }
  .ti_head {
    font: 800 40px/51px Source Serif Pro;
  }

  .p_content {
    width: unset;
    font: 300 22px/32px Source Serif Pro;
    height: auto;
  }
  .p_content_sec {
    width: unset;
    font: 300 22px/32px Source Serif Pro;
  }
  .mid_sec {
    gap: 7%;
  }
  .mid_cont_head {
    width: unset;
    margin: 27% 0px 0px 7%;
    font: 601 33px/50px source serif Pro;
  }
  .mid_cont {
    margin: 0% 0px 0px 7%;
    color: #ffffff;
    font: 300 20px/32px Source Serif Pro;
    width: 90%;
  }
  .mid_content {
    width: 47.44%;
  }
  .mid_img {
    width: 41.9%;
  }
  .say_it {
    font: 800 28px/41px Source Serif Pro;
  }
  .say_cont {
    font: 300 18px/28px Source Serif Pro;
  }
  .email_heading {
    font: 700 36px/54px Source Serif Pro;
  }
  .email_content {
    font: 300 18px/31px Source Serif Pro;
  }
  .bg_content_ {
    font: 700 32px/55px source serif Pro;
  }
  .ico_user {
    left: 90%;
  }
  .container {
    width: 96%;
  }
  .slide_img {
    width: 100%;
  }
  .containernew {
    width: 96%;
  }
  .main_header {
    margin: 0;
    width: 80%;
    font: normal 601 36px/53px Source Serif Pro;
    height: auto;
    padding-bottom: 15px;
  }
  .medium_content {
    margin: 0;
    width: 63%;
    height: auto;
    font: normal 20px/33px Source Serif Pro;
  }
  .header {
    width: 100%;
  }
  .features {
    width: 59%;
  }
  .ul_content {
    gap: 50px;
  }
  .fo_content_ {
    width: 50%;
    height: auto;
  }
  .new_124_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card_data {
    opacity: 1;
  }
  .card_header {
    font: 600 20px/22px source serif Pro;
    margin: 40px 21px;
    width: unset;
    /* height: auto; */
  }
  .w_ite {
    font: 600 20px/22px source serif Pro;
  }
  .footer_c {
    width: 100%;
    padding: 10% 5%;
    font: 17px/20px source serif Pro;
  }
  .card_footer {
    margin-top: 341px;
  }
  .po_st {
    width: unset;
    height: auto;
  }
  .hm_footer {
    width: unset;
  }
  .footer_b {
    width: 90%;
    margin: 70px 8px;
  }
  .hm_2_footer {
    width: unset;
  }
  .bt_btn {
    padding: 8px 0px 0px 10px;
  }
  .fourthcard_footer {
    margin: 362px 0px 0px 0px;
  }
  .md_img {
    width: 51.54%;
  }

  .hm_4_footer {
    width: unset;
    font: normal 400 18px/19px Source Serif Pro;
  }
  .par_div {
    margin: 0px 130px;
    width: unset;
    height: auto;
  }
  .pl_at {
    width: unset;
    height: auto;
  }
  .in_so {
    width: unset;
    height: auto;
  }
  .ic_on {
    width: unset;
    height: auto;
  }
  .screen_shot {
    width: 84.5%;
  }
  .crd_par_div {
    width: 100%;
    margin: 0px;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    gap: 0px 62px;
  }
  .lg_content {
    width: 100%;
    height: auto;
  }

  .lg_save_t {
    width: 100%;
    height: auto;
  }
  .lg_be_deliver {
    width: 100%;
    height: auto;
  }
  .lg_image {
    width: 51%;
    background-size: 100% 100%;
    border-radius: 25px;
  }
  .rep_crd_data {
    gap: 77px 64px !important;
  }
  .rep_3rd_headi {
    font: 700 25px/48px Source Serif Pro !important;
  }
  .crd {
    width: 45%;
    margin: 32px -10px 28px -14px;
  }
  .be_heading {
    font: 601 23px/22px source serif Pro;
  }
  .be_connect {
    font: 20px/22px source serif Pro;
  }
  .be_content {
    width: unset;
    font: 20px/24px source serif Pro;
  }
  .md_content {
    width: unset;
  }
  .md_content_da {
    width: 48%;
  }
  .md_st_trial {
    width: 90%;
    height: auto;
  }
  .md_paid_con {
    width: 100%;
    height: auto;
  }
  .lg_1_img {
    width: 80.41%;
    left: 48.94px;
    height: 18.32%;
  }
  .lg_data {
    display: flex;
    flex-direction: column;
    gap: 8%;
    width: 48.15%;
  }
  .lg_card {
    margin: 0px;
  }
  .lg_image {
    height: 1829px;
  }
  .bg_cd_img_4_17 {
    width: 46.41%;
    left: 3%;
  }
  .bg_cd_img_12_14 {
    width: 49.45%;
    left: 23%;
  }
  .img_2_q {
    width: 69.5%;
    right: 56px;
    height: 20.63%;
    top: 523px;
  }
  .img_3_q {
    width: 80.3%;
    right: 40px;
    height: 12.8%;
  }
  .img_4_q {
    width: 58.24%;
    right: 70px;
    height: 21.77%;
  }
  .bg_cd_img_50_58 {
    left: 52%;
    width: 44.66%;
  }
  .bg_cd_img_8 {
    width: 80.85%;
    left: 10%;
  }
  .bg_cd_img_6 {
    width: 63.41%;
    left: 35%;
  }
  .bg_cd_img_9 {
    left: 3%;

    width: 59.32%;
  }
  .bg_cd_img_7 {
    width: 14.08%;
    left: 49%;
  }
  .bg_cd_img_3_43 {
    width: 57.42%;
    left: 6%;
  }
  .bg_cd_img_f_59 {
    width: 46.29%;
    left: 35%;
  }
  .tool_header {
    margin: 141px 0px 0px 0px;
    width: unset;
    display: flex;
    justify-content: center;
  }
  .tool_combined {
    width: 88.05%;
  }
  .toolbox_list_icon {
    margin-left: 7.59%;
    width: 93.86%;
  }
  .tool_icon {
    /* width: 100.39%; */
    width: 68.39%;
  }
  .con_img {
    width: 51.54%;
  }
  .main_con {
    width: 45.69%;
  }
  .tool_dec {
    height: auto;
  }
  .con_heading {
    width: 77.44%;
    height: auto;
  }
  .active_tool_data {
    margin-left: 40%;
  }
  .con_cont {
    width: 100%;
    height: auto;
  }
  .tool_margin_top {
    width: 13%;
  }
  .inside_tool_div {
    padding: 0px 18px;
  }
  .tool_name {
    font-size: 10px;
  }
  .new_icons {
    width: 22.56%;
  }
  .evre_img {
    width: 51.96%;
  }
  .new_evre_cont {
    width: 36.7%;
  }
  .evre_heading {
    width: 100%;
    height: auto;
  }
  .env_div {
    width: 100%;
    background-size: 100% 100%;
    margin: 0;
    margin-bottom: 74px;
  }
  .m_w_simpler {
    width: 100%;
    background-size: 100% 100%;
    margin: 0;
  }
  .tools_for_everyone {
    gap: 11.5%;
  }
  .mw_botom {
    left: 24.03%;
    /* width: 54.22%; */
    width: auto;
    font: 200 26px/15px source serif Pro;
  }
  .footer {
    width: 100%;
  }
  .footer_content {
    margin: 0;
  }
  .footer_2nd {
    width: 57.1%;
  }
  .footer_1st {
    width: 22.7%;
  }
  .mw_top {
    /* width: 34.57%; */
    font: 37px/62px source serif Pro;
    left: 32.27%;
  }
  .btn_ {
    width: unset;
    left: 41.72%;
    top: 218px;
  }
  .bt_img {
    width: 32.2%;
  }
  .regt_img {
    left: 82.16%;
  }
  .mw_img {
    width: 11.53%;
  }
  .fo_heading {
    width: 100%;
    font: 27px/54px source serif Pro;
    height: auto;
  }
  .md_fo_content {
    gap: 39px;
  }
  .ml_25 {
    margin: 0;
  }
  .new_ml {
    width: 30%;
  }
  .font_18 {
    font: 15px/20px source serif Pro;
  }
  .font_20 {
    font: 17px/28px source serif Pro;
  }
  .logo {
    width: 5%;
    height: 5%;
    margin: -0.5% 0% 0% 3%;
  }
  .open_sol_box {
    left: 39.6%;
  }
  .cases_li {
    margin-bottom: 3%;
  }
  .divi_der {
    margin: 5% 0px;
  }
  .sale_4_sec_content {
    gap: 22px !important;
  }
  .sale_rating_crd {
    margin: 75px 0px 0px 30px !important;
    gap: 21px !important;
  }
  .sale_1_sec_heading {
    font: 700 34px / 52px Source Serif Pro !important;
  }
  .sale_1_sec_content {
    font: 21px / 34px Source Serif Pro !important;
  }
  .sale_7_heading {
    font: 25px / 46px source serif Pro !important;
  }
  .mark_6_cont {
    gap: 9.5px !important;
  }
  .pas_5th_sec_cont {
    gap: 14px !important;
  }
  .pr_mn_marg_bt {
    margin-left: 77px !important;
  }
  .re_ai_content {
    font: normal 18px / 25px source serif Pro !important;
  }
  .abt_heading {
    font: normal 700 33px / 54px Source Serif Pro !important;
  }
}

@media only screen and (max-width: 980px) {
  .tp_h {
    width: 56% !important;
  }
  .bs_pr_3_sec {
    font: 13px / 23px source serif pro !important;
  }

  .mark_2_sec_new {
    gap: 20px !important;
  }
  .top_heading {
    font: normal 701 26px / 51px Source Serif Pro !important;
  }
  .top_content {
    font: 16px / 34px Source Serif Pro !important;
  }
  .sale_4_sec_content {
    gap: 15px !important;
  }
  ._rate_content {
    font-size: 15px !important;

    line-height: 23px !important;
  }
  .sim_9th_lnk {
    font-size: 14px !important;
  }
  .pas_1st_heading {
    font: 701 27px/50px Source Serif Pro !important;
  }
  .pas_1st_content {
    font: 19px/33px Source Serif Pro !important;
  }
  .rep_heading {
    font: 700 27px/45px Source Serif Pro !important;
  }
  .rep_content {
    font: 18px/33px Source Serif Pro !important;
  }
  .rep_4th_heading {
    width: 83% !important;
    margin: 0px 0px 60px 11% !important;
  }
  .sim_m_heading {
    font: 701 27px/47px Source Serif Pro !important;
  }
  .rs_first_heading {
    font: 701 26px/46px Source Serif Pro !important;
  }
  .rs_sec_content {
    font: 12px/25px Source Serif Pro !important;
  }
  .rs_sec_heading {
    font: 701 25px/41px Source Serif Pro !important;
  }
  .rs_third_sec_content {
    gap: 3.4% !important;
  }

  .vt_cont_sec_1 {
    font: 300 17px/34px Source Serif Pro !important;
  }
  .md_remote {
    width: 65% !important;
    font: 38px/62px source serif Pro !important;
  }
  .vt_cont_sec_2 {
    font: 300 17px/34px Source Serif Pro !important;
  }
  .vt_rating_content {
    font: 800 24px/48px Source Serif Pro !important;
  }
  .vt_rt_heading {
    font: 700 33px/52px Source Serif Pro !important;
  }
  .vt_rt_content {
    font: 400 16px/29px Source Serif Pro !important;
  }
  .vt_sec_heading {
    font: 500 31px/48px Source Serif Pro !important;
  }
  .vt_sec_content {
    font: 300 17px/27px Source Serif Pro !important;
  }
  .rel_header {
    font: 700 23px/37px Source Serif Pro !important;
  }
  .rel_content {
    font: 400 16px/27px Source Serif Pro !important;
  }
  .rt_rating_heading {
    font: 700 23px/54px Source Serif Pro !important;
  }
  .rt_left_content_cont {
    font: 15px/22px Source Serif Pro !important;
  }
  .left_icon_content {
    font: 13px/21px Source Serif Pro !important;
  }
  .rt_cd_heading {
    font: 700 23px/39px Source Serif Pro !important;
  }
  .cent_1_sec_content {
    font: 400 16px/34px Source Serif Pro !important;
    height: auto;
  }
  .cent_3_sec_heading {
    font: 600 33px/51px Source Serif Pro !important;
  }
  .cent_3_sec_content {
    font: 17px/31px Source Serif Pro !important;
  }
  .rt_text {
    width: 90%;
    font: 14px/18px Source Serif Pro !important;
  }
  .cent_5th_sec_heading {
    width: 70.32%;
  }
  .int_heading {
    font: 700 34px/54px Source Serif Pro;
  }
  .img_int {
    margin: -32% 0px 0px 36%;
  }
  .int_fi_div {
    width: 90%;
    height: 60%;
  }
  .int_rt_content {
    font: 600 25px/48px Source Serif Pro;
  }
  .int_super {
    font: 800 22px/36px Source Serif Pro;
  }
  .ti_head {
    font: 800 32px/47px Source Serif Pro;
  }
  .ti_img {
    height: 580px;
  }
  .img_ti_div {
    height: 500px;
  }
  .mid_cont_head {
    font: 601 30px/47px source serif Pro;
  }
  .say_it {
    font: 800 27px/38px Source Serif Pro;
  }
  .say_cont {
    font: 300 17px/27px Source Serif Pro;
  }

  .email_heading {
    font: 700 30px/52px Source Serif Pro;
  }
  .email_content {
    font: 300 17px/30px Source Serif Pro;
  }
  .img_em_int {
    height: 478px;
  }
  .int_email_img {
    height: 575px;
  }
  .img_wi_cont {
    margin: 35px 0px 113px 2.4%;
  }
  .mid_sec {
    margin: 40px 25px 0px;
  }
  .mid_cont {
    font: 300 20px/31px Source Serif Pro;
  }
  .int_rt_content {
    margin-top: 153px;
  }
  .pl_at {
    width: unset;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 601 36px/34px source serif Pro;
  }

  .tool_header {
    font: normal 700 27px/39px Source Serif Pro;
  }

  .evre_sup {
    font: normal 700 25px/34px Source Serif Pro;
  }

  .evre_heading {
    font: 700 25px/34px Source Serif Pro;
  }

  .in_so {
    font: 601 36px/34px source serif Pro;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ic_on {
    width: 11.29%;
    height: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0px;
  }
  .par_div {
    display: flex;
    justify-content: center;
  }
  .slide_img {
    width: 100%;
    height: 100%;
  }
  .be_content {
    font: 16px/24px source serif Pro;
  }
  .tool_dec {
    font: normal 21px/34px Source Serif Pro;
  }
  .lg_save_t {
    font: normal 700 25px/34px Source Serif Pro;
  }
  .md_st_trial {
    font: normal 700 27px/38px Source Serif Pro;
  }
  .lg_be_deliver {
    font: 400 16px/23px Source Serif Pro;
  }
  .lg_data {
    gap: 9%;
  }
  .btn {
    margin: -1% 2% 0% 0%;
  }
  .open_box {
    width: 54.5%;
  }
  .bre-os p {
    font: 600 12.5px/21px source serif Pro;
  }
  .li_data {
    margin-top: 5%;
  }
  .ul_list {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .tp_h {
    width: 38% !important;
  }
  .card_header {
    width: 50%;
  }

  .bs_pr_3_sec {
    font: 12px / 21px source serif pro !important;
  }

  .bs_pr_1_content {
    font: normal 15px / 25px source serif Pro !important;
  }

  .p_r_sec_cont {
    font: 600 32px / 52px source serif Pro !important;
  }

  .light_pricing_crd {
    gap: 7.6% !important;
  }
  .pricing_lig_data {
    width: 44.57% !important;
    margin-bottom: 13px !important;
  }
  .werty_price {
    font: 12px / 22px soure serif Pro !important;
  }
  .light_pri {
    font: 600 27px / 54px source serif Pro !important;
  }
  .p_r_cont_sec {
    font: 16px / 34px source serif Pro !important;
  }

  .new_pricing_ {
    font: 700 35px / 54px source serif Pro !important;
  }
  .best_pric_content {
    font: 300 12px / 21px source serif Pro !important;
  }
  .best_pric_ {
    font: 32px / 48px source serif Pro !important;
  }
  .best_pric_per_m {
    font: 14px / 19px source serif Pro !important;
  }
  .font_pricing_c {
    font: 13px / 25px source serif Pro !important;
  }
  .best_pr_btn {
    font: 10px / 21px source serif Pro !important;
  }
  .best_heading {
    font: 600 23px / 34px source serif pro !important;
  }
  .re_ai_content {
    font: normal 13px / 25px source serif Pro !important;
  }
  .cs_5_sec {
    margin: 0px 0px 40px 18.4% !important;
    width: 66.19% !important;
  }
  .top_heading {
    font: normal 701 20px / 39px Source Serif Pro !important;
  }
  .top_content {
    font: 16px / 32px Source Serif Pro !important;
  }
  .salmarke_6_content {
    gap: 6px !important;
  }
  .sale_rating_crd {
    margin: 75px 0px 0px 19px !important;
    gap: 15px !important;
  }
  ._rate_content {
    font: 13px/19px source serif Pro !important;
    width: 98% !important;
  }
  .sale_6_sec {
    gap: 24px !important;
  }
  .sale_6_content {
    width: 50.53% !important;
    gap: 15px !important;
  }
  .sale_7_heading {
    font: 21px / 46px source serif Pro !important;
  }
  ._rate {
    font-size: 30px !important;
    line-height: 39px !important;
  }
  .sale_5_sec {
    margin: -1px 0px 100px 23% !important;
  }
  .sale_1_sec_heading {
    font: 700 28px / 50px Source Serif Pro !important;
  }
  .sale_1_sec_content {
    font: 17px / 36px Source Serif Pro !important;
  }
  .sale_3_sec_content {
    gap: 26px;
  }
  .sim_9th_lnk {
    font-size: 11px !important;
  }
  .tranq_6_2_cont {
    bottom: 72px !important;
  }
  .beba_4rd_sect {
    width: 100% !important;
  }
  .sim_4th_ico_cont {
    gap: 2px !important;
  }
  .pas_1st_content {
    font: 15px/31px Source Serif Pro !important;
  }
  .pas_1st_heading {
    font: 701 22px/43px Source Serif Pro !important;
  }
  .rep_3rd_headi {
    font: 700 18px/48px Source Serif Pro !important;
  }
  .rs_first_heading {
    font: 701 19px/46px Source Serif Pro !important;
  }
  .rs_fsec_content {
    font: 13px/33px Source Serif Pro !important;
  }
  .rs_sec_heading {
    font: 701 20px/41px Source Serif Pro !important;
  }
  .third_sec_heading {
    font: 800 20px/37px Source Serif Pro !important;
  }
  .coll_heading {
    width: 79% !important;
    margin: 0px 0px 40px 11% !important;
  }
  .rs_sec_heading_ {
    width: 82% !important;
  }
  .vt_rt_heading {
    font: 700 26px/52px Source Serif Pro !important;
  }
  .crd_relation {
    margin: 0px 0px 100px -27px !important;
  }
  .vt_rt_content {
    font: 400 15px/29px Source Serif Pro !important;
  }
  .vt_crd {
    width: 58% !important;
    margin-left: 20% !important;
  }
  .rt_cd_heading {
    font: 700 18px/39px Source Serif Pro !important;
  }
  .vt_crd_content {
    width: 380px !important;
  }
  .lt_headding {
    font: italic 24px/48px source serif Pro !important;
  }
  .rel_header {
    font: 700 15px/29px Source Serif Pro !important;
  }
  .rel_content {
    font: 400 12px/22px Source Serif Pro !important;
  }
  .fr_cont_header {
    font: 700 23px/35px Source Serif Pro !important;
  }
  .fs_18_h_25_left_blk {
    font: 400 15px/18px Source Serif Pro !important;
  }
  .heading_30_41_left_blk {
    font: normal 700 22px/35px Source Serif Pro !important;
  }
  .beba_3rd_section {
    gap: 25px !important;
  }
  /* .sim_m_heading {
  font: 701 29px/51px Source Serif Pro !important;
} */
  .sim_m_content {
    font: 17px/29px Source Serif Pro !important;
  }
  .sm_4th_header {
    width: 92% !important;
  }
  .sm_4th_sec {
    gap: 3.4% !important;
    width: 51.3% !important;
  }
  .sim_m_heading {
    font: 701 27px/42px Source Serif Pro !important;
  }
  .rt_card_h {
    width: 57% !important;
  }
  .fr_heading {
    width: 67% !important;
    font: 700 22px/38px Source Serif Pro !important;
  }
  .rt_rating_heading {
    font: 700 18px/54px Source Serif Pro !important;
  }
  .rt_crd_main_m {
    gap: 19px !important;
  }
  .rt_left_content_heading {
    font: 700 24px/32px Source Serif Pro !important;
  }
  .rt_left_content_cont {
    font: 13px/20px Source Serif Pro !important;
  }
  .bld_up_content_bd {
    top: 17% !important;
    font: 601 22px/48px source serif Pro !important;
  }
  .cent_1_sec_heading {
    font: 700 26px/50px Source Serif Pro !important;
  }
  .cent_1_sec_content {
    font: 400 13px/34px Source Serif Pro !important;
    /* height: auto !important; */
  }
  .cent_2_sec_content {
    font: 700 24px/54px Source Serif Pro !important;
  }
  .cent_3_sec_img {
    height: 308px !important;
  }
  .cent_3_sec_heading {
    font: 600 23px/38px Source Serif Pro !important;
  }
  .cent_3_sec_content {
    font: 15px/19px Source Serif Pro !important;
  }
  .cent_3_sec {
    gap: 61px !important;
    margin: 0px 0px 0px 88.36px !important;
  }
  .crd_detail_h {
    /* width: 74% !important; */
    margin-left: 12%;
  }
  .vt_crd_content {
    width: 82% !important;
    font: 300 16px/25px Source Serif Pro !important;
  }
  .vt_sec_ro_ele {
    font: 700 15px/38px Source Serif Pro !important;
  }
  .vt_cont_sec_1 {
    font: 300 14px/34px Source Serif Pro !important;
  }
  .vt_cont_sec_2 {
    font: 300 14px/34px Source Serif Pro !important;
  }
  .md_remote {
    font: 31px/62px source serif Pro !important;
  }
  .vt_rating_content {
    font: 800 19px/48px Source Serif Pro !important;
  }
  .rat_percent {
    font-size: 27px !important;
  }
  .vt_suff_header {
    font: 701 22px/37px Source Serif Pro !important;
  }
  .ft_18_l_ht_25 {
    font: 400 10px/24px Source Serif Pro !important;
  }
  .vt_wt_cont_header {
    font: 701 22px/41px Source Serif Pro !important;
  }
  .meet_heading {
    font: 700 22px/37px Source Serif Pro !important;
  }
  .meet_p {
    font: 400 11px/18px Source Serif Pro !important;
  }
  .vt_link_tag {
    font: 601 13px/27px Source Serif Pro !important;
  }
  .cent_5th_sec_heading {
    font: 700 18px/41px Source Serif Pro !important;
  }
  .cent_rtig {
    gap: 17px !important;
  }
  .rt_text {
    font: 12px/18px Source Serif Pro !important;
  }
  .rat_percent {
    font-size: 30px;

    margin: 19px 0px 6px 0px;
  }
  .int_img_de {
    margin: 26px 0px 0px 0%;
    font: 13px/14px source serif Pro;
  }
  .ti_head {
    font: 800 30px/47px Source Serif Pro;
    width: unset;
    margin: 27% 0px 0px 0px;
  }

  .int_p_tag {
    font: normal 600 23px/57px Source Serif Pro;
  }
  /* .img_int {
    position: absolute;
    margin: -66% 0px 0px 34%;
    width: 25%;
    height: 47%;
  } */
  .int_fi_div {
    width: 80%;
    height: 70%;
  }
  .int_sec_div {
    width: 85%;
    height: 70%;
  }
  .img_int {
    position: absolute;
    margin: -28% 0px 0px 34%;
    width: 22%;
    height: 47%;
  }
  .img_int_de {
    margin: -22px 0px 0px 37%;
    font-size: 13px;
  }
  .int_img_det {
    font: 13px/105px source serif Pro;
    margin: -8% 0px 0px -7%;
    position: relative;
  }
  .main_header {
    font: normal 601 27px/40px Source Serif Pro;
  }
  .medium_content {
    width: 70%;
    font: normal 18px/30px Source Serif Pro;
  }
  .po_st {
    font: italic 400 25px/34px Source Serif Pro;
  }
  .info_solution {
    margin: 7% 0px 0px 0px;
  }
  .be_connect {
    font: 18px/20px source serif Pro;
  }
  .be_heading {
    width: unset;
    font: 601 20px/28px source serif Pro;
  }
  .be_content {
    width: unset;

    font: 15px/24px source serif Pro;
  }
  .de {
    font-size: 16px;
  }
  .md_st_trial {
    font: normal 700 21px/34px Source Serif Pro;
    width: 100%;
  }
  .md_paid_con {
    font: 300 16px/24px Source Serif Pro;
  }
  .a_link_md {
    font: 600 16px/28px Source Serif Pro;
  }
  .md_img {
    height: 70%;
  }
  .lg_content {
    margin: 0;
  }
  .lg_save_t {
    font: normal 700 21px/30px Source Serif Pro;
  }
  .lg_be_deliver {
    font: 400 15px/20px Source Serif Pro;
  }
  .btn {
    width: 18.84%;
    display: flex;
    justify-content: center;
    margin: -1.5% 2% 0%;
  }
  .re_btn {
    font: 600 15px/20px Source Serif Pro;
    /* height: 100%;
    width: 100%; */
  }
  .ul_content {
    gap: 8% !important;
  }
  .new_btn {
    font: 600 15px/24px source serif Pro;
  }
  .fo_decri {
    margin: 1px 0px 0px 6px;
  }
  .fo_content_ {
    width: 63%;
  }
  .fo_holo {
    text-align: end;
    font: italic 13px/21px source serif Pro;
    margin: -1px -13px 0px 0px;
  }
  .why_b {
    font-size: 14px;
  }
  .li_da {
    margin-top: 19% !important;
    font-size: 12px !important;
  }
  .use_c {
    font-size: 15px;
  }
  .secon_div {
    width: 32%;
    margin: 27px 0px 0px 3%;
  }
  .cases_li {
    font-size: 11px;
  }

  .card_data {
    display: flex;
    flex-wrap: wrap;
  }
  .card {
    width: 50%;
    /* margin: 10px 19px; */
  }
  .footer_b {
    margin: 15% 5% !important;
    width: 77% !important;
  }
  .bt_btn {
    padding: 2.5% 0px 0px 8%;
    font-size: 16px;
  }
  .pl_at {
    font: 601 25px/28px source serif Pro;
  }
  .in_so {
    font: 601 25px/29px source serif Pro;
  }
  .lg_data {
    gap: 8%;
  }
  .lg_1_img {
    width: 72.41%;

    height: 14.32%;
  }
  .bg_cd_img_4_17 {
    width: 39.41%;
    left: 6%;
    height: 10%;
  }
  .bg_cd_img_12_14 {
    width: 39.45%;
    left: 22%;
    top: 33%;
    height: 9%;
  }
  .bg_cd_img_50_58 {
    left: 52%;
    width: 38.66%;
    height: 7%;
  }
  .bg_cd_img_8 {
    width: 70.85%;
    left: 12%;
    height: 8%;
  }
  .bg_cd_img_6 {
    width: 55.41%;
    left: 35%;
    height: 6%;
  }
  .bg_cd_img_9 {
    left: 5%;
    width: 51.32%;
    height: 4%;
  }
  .bg_cd_img_7 {
    width: 13.08%;
    left: 49%;
    height: 8.5%;
  }
  .bg_cd_img_3_43 {
    width: 51.42%;
    left: 10%;
    height: 12%;
  }
  .bg_cd_img_f_59 {
    width: 43.29%;
    left: 37%;
    height: 9%;
  }
  .tool_header {
    font: normal 700 20px/34px Source Serif Pro;
  }
  .tool_combined {
    width: 100%;
  }
  .evre_heading {
    font: 700 18px/28px Source Serif Pro;
  }
  .evre_sup {
    font: normal 700 18px/28px Source Serif Pro;
    margin: 1% 0% 0% 10%;
  }
  .evre_img {
    width: 51.96%;
    height: 100%;
  }
  .brd_div {
    height: 84%;
    gap: 9px;
    margin: 11px 0px 0px 0px;
  }
  .heading_30_41_left_blk {
    font: normal 700 21px/30px Source Serif Pro;
  }
  .con_heading {
    width: 100%;
  }
  .fs_18_h_25_left_blk {
    font: 400 15px/20px Source Serif Pro;
  }
  ._link {
    font-size: 18px;
  }
  .con_img {
    height: 77%;
  }
  .new_icons {
    height: 100%;
  }
  .new_content {
    margin: 80px 0px 0px 33px;
  }
  .mw_top {
    font: 28px/69px source serif Pro;
  }
  .mw_botom {
    font: 200 21px/26px source serif Pro;
  }
  .btn_ {
    width: 18%;
  }
  .fo_heading {
    font: 21px/52px source serif Pro;
  }
  .font_20 {
    font: 14px/20px source serif Pro;
  }
  .font_18 {
    font: 12px/15px source serif Pro;
  }
  .new_ml {
    width: 34%;
  }
  .md_fo_content {
    gap: 22px;
  }
  .footer {
    height: auto;
  }
  .fo_content_ {
    font: 8px/13px source serif Pro;
    width: 53%;
  }
  .fo_decri span {
    font: italic normal normal 9px/15px Source Serif Pro;
  }
  .int_heading {
    font: 700 25px/41px Source Serif Pro;
  }
  .int_contnt {
    font: 300 20px/31px Source Serif Pro;
    width: 100%;
    height: auto;
  }
  .int_super {
    font: 800 19px/36px Source Serif Pro;
  }
  .int_p_tag {
    font: normal 600 22px/55px Source Serif Pro;
  }
  .int_cont {
    width: 100%;
  }
  .say_it {
    font: 800 21px/30px Source Serif Pro;
  }
  .say_cont {
    font: 300 13px/25px Source Serif Pro;
  }
  .bg_content_ {
    font: 700 24px/55px source serif Pro;
  }
  .ico_user {
    left: 91%;
    width: 12%;
    height: 35%;
    top: -14%;
  }
  .nex_icon {
    left: 81%;
    top: 78%;
    width: 15%;
    height: 35%;
  }
  .p_content {
    font: 300 20px/30px Source Serif Pro;
  }
  .int_img_email {
    width: 37%;
    height: 37%;
    margin: -34px 6px 0px 77.38%;
  }
  .int_img_out {
    margin: 49% -32%;
  }
  .int_img_t {
    width: 40%;
    height: 40%;
    margin: -14px 0px 0px 78%;
  }
  .li_data:not(:first-child) {
    margin-top: 5px;
    font-size: 13px;
  }
  .li_data {
    /* margin-top: 5%; */
    font-size: 13px;
  }
  .int_mid_rt_cont {
    width: 52%;
    font: 700 30px/52px Source Serif Pro;
  }
  .p_content_sec {
    width: unset;
    font: 300 20px/30px Source Serif Pro;
  }
  .new_tand_c {
    font: normal 14px / 28px source serif Pro !important;
  }
  .t_s_2_div {
    font: 12px / 25px source serif pro !important;
  }
  .abt_content {
    font: normal 13px / 28px source serif Pro !important;
  }
  .abt_heading {
    font: normal 700 25px / 41px Source Serif Pro !important;
  }
}

@media only screen and (max-width: 479px) {
  .medium_content {
    width: 65%;
    font: normal 14px / 27px Source Serif Pro;
  }
  .features {
    display: none;
  }
  .re_btn_new {
    display: none;
  }
  .lg_1_img {
    left: 23.94px;
  }
  .lg_save_t {
    font: normal 600 15px / 23px Source Serif Pro;
  }
  .lg_a_tag {
    color: black;
    font: 600 12px / 16px Source Serif Pro;
  }
  .lg_be_deliver {
    font: 400 12px / 20px Source Serif Pro;
    margin: 13px 0px;
  }
  .lg_data {
    gap: 6%;
  }
  .lg_content {
    gap: 11px;
  }
  .toolbox_list_icon {
    margin-left: 1.59%;
    width: 100%;
  }
  .tool_list {
    width: 99%;
  }
  .tools_for_everyone {
    margin: 200px 0px 0px 8px;
  }
  .evre_sup {
    font: normal 700 14px / 17px Source Serif Pro;
  }
  .brd_div {
    height: 69%;
    gap: 0px;
    margin: 11px 0px 0px 0px;
  }
  .new_content {
    margin: 80px 0px 0px 4px;
    gap: 12px;
  }
  .dis_gp_55 {
    display: flex;
    gap: 24px;
    margin-top: 9px;
  }
  .env_div {
    height: 252px;
  }
  .fo_heading {
    font: 19px / 25px source serif Pro;
  }
  .font_20 {
    font: 11px / 17px source serif Pro;
  }
  .fo_decri span {
    margin: 0;
  }
  .footer_3rd {
    margin: 104px 0px 0px 12px;
    text-align: center;
  }
  .mw_top {
    top: 27px;
    left: 23.27%;
    font: 23px / 69px source serif Pro;
  }
  .mw_botom {
    font: 200 15px / 26px source serif Pro;
    left: 18.03%;
    top: 98px;
  }
  .btn_ {
    left: 33.72%;
    top: 157px;
  }
  .m_icons {
    flex-wrap: wrap;
    gap: 3px;
  }
  .re_btn {
    height: 35px;
    width: 128px;
  }
  .mw_img {
    height: 50%;
  }
  .bt_img {
    height: 20%;
    top: 192px;
  }
  .regt_img {
    left: 80.16%;
    height: 20%;
    top: 198px;
  }
  .m_w_simpler {
    height: 261px;
  }
  .mw_right_img {
    width: 78px;
    height: 124px;
  }
  .font_18 {
    font: 9px / 14px source serif Pro;
  }
  .md_fo_content {
    gap: 25px;
  }
  .m_link_icons {
    width: 20px;
    height: 20px;
  }
  .foter_logo {
    width: 106px;
  }
  .bearish {
    width: 30%;
  }
  .be_logo {
    font: 17px / 20px source serif Pro;
  }
  .be_content {
    width: 88%;
  }
  .env_lnk {
    top: 167px;
    left: 43px;
  }
  .main_con {
    gap: 10px;
  }
  .con_heading {
    width: 100%;
    margin: 0;
  }
  .con_link {
    gap: 0px;
  }
  ._link {
    font-size: 16px;
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    right: 6%;
  }
  .card_data {
    gap: 20px 0px;
  }
  .card {
    width: 100% !important;
    margin: 0% 0%;
  }
  .par_div {
    width: 79%;
    margin: 0px 0px 0px 47px;
  }
  .pl_at {
    font: 601 19px / 22px source serif Pro;
    width: 48%;
  }
  .crd {
    width: 82%;
  }
  .in_so {
    font: 601 19px / 28px source serif Pro;
    width: 65%;
  }
  .ic_on {
    width: 12.29%;
    margin: 20px 4px;
  }
  .main_header {
    width: 87%;
  }
  .w_ite {
    font: 32px / 45px source serif Pro;
  }
  .footer_c {
    width: 89% !important;
    text-align: center;
    font: 15px / 30px source serif Pro;
  }
  .hm_footer {
    width: 100%;
  }
  .hm_2_footer {
    width: 100%;
  }
  .md_content_da {
    width: 100%;

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 33px;
  }
  .md_content {
    flex-wrap: wrap;
    margin-bottom: 350px;
    justify-content: center;
  }
  .md_img {
    width: 96%;
  }
  .md_st_trial {
    width: 90%;
    font: 601 27px / 41px source serif Pro;
    text-align: center;
  }
  .md_paid_con {
    font: 19px/32px source serif Pro;
    text-align: center;
    margin-bottom: 18px;
    width: 76%;
  }
  .hm_4_footer {
    font: 15px / 30px source serif Pro;
  }
  .footer_b {
    width: 400px;
    height: 44px;
  }
  .bt_btn {
    font-size: 16px;
    padding: 2.5% 0px 9% 8%;
  }
  .tp_h {
    width: 52% !important;
  }
  .card_header {
    width: 75%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 28px / 42px source serif Pro;
  }
  .card_footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 301px;
  }
  .fourthcard_footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 649px) {
  .card_data {
    display: flex;
    flex-wrap: wrap;
  }
  .card {
    width: 50%;
    /* margin: 10px 19px; */
  }
}
@media only screen and (max-width: 980px) {
  .footer_b {
    width: 90%;
    margin: 85px 8px;
  }
}
@media only screen and (max-width: 320px) {
  .tool_list {
    height: auto;
  }
  .tool_combined {
    height: auto;
  }
  .tool_margin_top {
    margin: 0px 0px 0px 0px !important;
  }
  .tool_icon {
    height: 24px !important;
  }
  .tool_de_icon {
    width: 36px !important;
  }
  .fo_holo {
    text-align: end;
    font: italic 7px / 10px source serif Pro;
    margin: -1px 2px 0px 0px;
}
  .tool_de{
    font: normal 700 23px / 30px Source Serif Pro !important;
    margin: 0;
  }
  .active_tool_data {
    margin-left: 40%;
    margin-top: 35px !important;
  }
  .footer_b {
    width: 90%;
    margin: 85px 8px;
  }
  .card_header {
    width: 74%;
    font: 25px / 42px source serif Pro;
  }
  .footer_c {
    width: 92% !important;
    font: 12px / 27px source serif Pro;
  }
  .medium_content {
    font: normal 10px / 22px Source Serif Pro;
  }
  .w_ite {
    font: 25px / 45px source serif Pro;
  }
  .hm_4_footer {
    font: 12px / 30px source serif Pro;
  }
  .po_st {
    font: italic 400 23px / 34px Source Serif Pro;
  }
  .pl_at {
    font: 601 17px / 22px source serif Pro;
  }
  .in_so {
    font: 601 15px / 28px source serif Pro;
  }
  .par_div {
    margin: 0px 0px -17px 28px;
  }
  .be_connect {
    font: 16px / 18px source serif Pro;
  }
  .be_heading {
    font: 601 16px / 19px source serif Pro;
  }
  .be_content {
    font: 12px / 24px source serif Pro;
  }
  .de {
    font-size: 12px;
  }
  .md_st_trial {
    font: 601 20px / 36px source serif Pro;
  }
  .md_paid_con {
    font: 14px / 32px source serif Pro;
  }
  .a_link_md {
    font: 600 13px / 28px Source Serif Pro;
  }
  .md_content_da {
    margin: 0px 0px 0px 6px;
  }
  .md_sm_back {
    margin: 0px 0px 0px 15px;
  }
  .lg_content {
    gap: 10px;
  }
  .screen_shot {
    margin: 44px 0px 0px 20px;
  }
  .lg_be_deliver {
    font: 400 10px / 15px Source Serif Pro;
  }
  .tool_dec {
    font: normal 14px / 27px Source Serif Pro;
  }
  .new_evre_cont {
    width: 45.7%;
  }
  .tools_for_everyone {
    gap: 3.5%;
  }
  .evre_heading {
    font: 700 15px / 23px Source Serif Pro;
  }
  .evre_sup {
    font: normal 700 11px / 17px Source Serif Pro;
  }
  .heading_30_41_left_blk {
    font: normal 700 17px / 25px Source Serif Pro !important;
  }
  .fs_18_h_25_left_blk {
    font: 400 12px / 17px Source Serif Pro !important;
  }
  .con_img {
    height: 67%;
  }
  .mw_top {
    font: 15px / 62px source serif Pro;
  }
  .mw_botom {
    font: 200 11px / 25px source serif Pro;
  }
  .mw_right_img {
    width: 43px;
    height: 121px;
  }
  .foter_logo {
    width: 70px;
  }
  .fo_heading {
    font: 15px / 25px source serif Pro;
  }
  .md_fo_content {
    gap: 13px;
  }
  .font_20 {
    font: 9px / 17px source serif Pro;
  }
  .font_18 {
    font: 7px / 14px source serif Pro;
  }
  .fo_content_ {
    font: 6px / 13px source serif Pro;
  }
  .footer_3rd {
    margin: 104px 0px 0px 0px;
  }
  ._link {
    font-size: 10px;
  }
  .tool_name {
    font-size: 7px;
  }
  .bt_btn {
    font: 13px / 18px source serif Pro;
    padding: 4.5% 0px 9% 5%;
  }
  .main_header {
    width: 91%;
    font: normal 601 20px / 40px Source Serif Pro;
  }
}
