.home_page {
  margin-top: 200px;
  text-align: center;
  font-family: serif;
}
.medium_content {
  width: 712px;
  height: 68px;
  margin: 20px 0px 0px 327px;
  text-align: center;
  line-height: 34px;
  color: #000000;
  font-family: Source Serif Pro Light;
  opacity: 100%;
  font: normal 25px/34px Source Serif Pro;
}
.main_header {
  width: 1074px;
  height: 69px;
  font: normal 600 50px/69px Source Serif Pro;
  text-align: center;
  margin: 0px 0px 0px 146px;
  font-family: Source Serif Pro Semibold;
}
.btn_m {
  margin-top: 40px;
}
.tp_h {
  width: 47% !important;
}
.re_btn {
}
.card {
  height: 700px;
  width: 25%;
  /* background: url(../../assets/ally-griffin-VD7cu7bKHmk-unsplash@2x.png); */
}
.card_data {
  margin-top: 40px;
  display: flex;
  font-family: serif;
  width: 100%;
  opacity: 0.8;
}
.crd_img {
  width: 100%;
  height: 100%;
}
.card_header {
  margin: 40px 20px 0px 42px;
  width: 63%;
  /* height: 68px; */
  font-size: 25px;
  font-weight: 600;
  font-family: serif;
  color: #000000;
  text-align: justify;
  letter-spacing: 0.5px;
}
.w_ite {
  color: #fff;
  margin: 40px 52px 0px 0px;
  font-size: 25px;
  font-weight: 500;
  font-family: auto;
}
.card_footer {
  margin-top: 96%;
  width: 100%;
  height: 275px;
  opacity: 1;
  font: normal 400 18px/25px Source Serif Pro;
  color: #000000;
}
.hm_footer {
  width: 295px;
}
.hm_2_footer {
  width: 301px;
}
.hm_4_footer {
  width: 292px;
  height: 125px;
  font: normal 400 18px/25px Source Serif Pro;
}
.fourthcard_footer {
  margin: 356px 0px 0px 0px;
  /* width: 379px; */
  /* width: 341px; */
  height: 275px;
  color: #fff;
}
.footer_c {
  /* width: 276px; */
  padding: 27px 0px 0px 33px;
  text-align: left;
  font-weight: 500;
  height: 125px;
  font-size: 18px;
}
.footer_b {
  margin: 64px 0px 0px 33px;
  text-align: left;
  width: 240px;
  height: 35px;
  border: 1px solid #000000;
  border-radius: 23px;
}
.footer_b_w_b {
  border: 1px solid #fff;
}

.po_st {
  margin: 50px 0px 0px 0px;
  font-family: Source Serif Pro Italic;
  font: italic 400 30px/41px Source Serif Pro;
}
.bt_btn {
  text-align: left;
  font-size: 13px;
  height: 17px;
  border: none;
  padding: 9px 0px 0px 24px;
  background: none;
  opacity: 1;
  font-family: serif;
}
.bt_w_e {
  color: #fff;
}
.info_solution {
  margin: 107px 0px 0px 0px;
}
.md_content {
  width: 1333px;
  display: flex;
  margin: 140px 0px 0px 0px;
  height: 550px;
  gap: 37px;
  opacity: 1;
  font-family: serif;
}
.md_img {
  width: 670px;
}
.md_content_da {
  width: 663px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 40px 0px 0px 33px;
}
.img_md {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.md_st_trial {
  text-align: left;
  width: 573px;

  height: 82px;
  font: normal 700 30px/41px Source Serif Pro;
}
.md_paid_con {
  width: 593px;
  letter-spacing: -0.1px;
  height: 50px;
  text-align: left;
  font: 300 18px/25px Source Serif Pro;
}
.md_link {
  display: flex;
  margin-top: -6px;
}
.a_link_md {
  color: #000000;
  opacity: 1;
  font: 600 20px/28px Source Serif Pro;
}
.md_sm_back {
  margin: 0px 0px 0px 30px;
  width: 40px;
  height: 40px;
  opacity: 1;
  background: url(../../assets/roundIcons/Group\ 4863.png);
}
.m_d {
  margin: 6px 0px 0px;
}
.md_arrow {
  width: 25px;
  height: 25px;
  margin: 7.2px;
}

.lg_content {
  margin: 180px 0px 0px 0px;
  width: 1366px;
  /* background: red; */
  height: 1819px;
  display: flex;
  opacity: 1;
  gap: 40px;
}
.lg_image {
  width: 663px;
  max-width: 100%;
  background-image: url(../../assets/Group\ 4891@2x.png);
  background-size: cover;

  opacity: 1;
}
.lg_1_img {
  width: 533.31px;
  height: 333.46px;

  top: 48.37px;
  left: 71.94px;
  opacity: 1;
}
.lg_2_img {
  width: 461px;
  height: 375px;
  /* position: absolute; */
  top: 523px;
  left: 114px;
  opacity: 1;
}
.lg_3_img {
  width: 533px;
  height: 233px;
  /* position: absolute; */
  bottom: 556px;
  left: 79px;
  opacity: 1;
}
.lg_4_img {
  width: 386px;
  height: 396px;
  bottom: 43px;
  /* position: absolute; */
  left: 153px;
  opacity: 1;
}
.lg_img {
  width: 100%;
  height: 100%;
}
.lg_data {
  /* width: 626px; */
  font-family: serif;
  text-align: left;
}
.lg_save_t {
  width: 561px;
  height: 82px;
  text-align: left;
  /* line-height: 41px;
  font-size: 30px; */
  font: normal 700 30px/41px Source Serif Pro;
}
.lg_save_t_w {
  width: 623px;
}
.lg_be_deliver {
  font: 400 18px/25px Source Serif Pro;
  width: 626px;
  opacity: 1;
  margin: 25px 0px;
}
.lg_a_tag {
  color: black;
  font: 600 20px/28px Source Serif Pro;
}
.lg_a_div {
  margin: 6px 0px 0px 0px;
}
.env_ar_icon {
  width: 40px;
  height: 40px;
}
.env_lnk_u {
  text-decoration: underline;
  color: #000000;
}
.lg_link {
  display: flex;
  gap: 15px;
}
.lg_card {
  margin-bottom: 185px;
}
.tool_header {
  margin: 141px 0px 0px 314px;
  width: 739px;
  /* height: 41px; */

  text-align: left;

  font: normal 700 30px/41px Source Serif Pro;
}

.tool_list {
  margin: 0px 95px 0px 0px;
  /* background: blue; */
  display: flex;
  height: 130px;
}
.toolbox_list_icon {
  margin: 45px 0px 0px 172px;
  /* background: red; */
  width: 1117px;
  height: 419px;
  font-family: serif;
}
.tool_combined {
  width: 983px;
  height: 130px;
  background: url(../../assets/listicons/Group\ 4893.png) left top,
    url(../../assets/listicons/Group\ 4893.png) right top;
  background-size: 50% 112%, 80% 88%;
  border-radius: 35px;
}
.inside_tool_div {
  display: flex;
  gap: 6px;
  padding: 0px 55px;
}
.tool_margin_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 104px;
  margin: 20px 0px 0px 0px;
}

.cursor_p {
  cursor: pointer;
}

.tool_icon {
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.tool_name {
  height: 25px;
  font-size: 18px;
  text-align: left;
  margin: 4px 0px 0px 0px;
  color: #ffff;
}
.img_w_100 {
  width: 100%;
}
.img_w_10 {
  width: 100%;
  mix-blend-mode: exclusion;
}
.active_tool_data {
  margin: 87px 0px 0px 546px;
  /* background: blue; */
}
.tool_detail {
  display: flex;
  gap: 10px;
}
.tool_de_icon {
  width: 60px;
  height: 60px;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
}
.tool_de {
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  color: #ad2121;

  align-items: center;
  text-align: left;

  font: normal 700 30px/41px Source Serif Pro;
}
.tool_dec {
  margin: 6px 0px 0px 0px;
  text-align: left;
  /* font-size: 25px;
  font-size: 25px;
  line-height: 0.8cm; */
  height: 170px;
  /* letter-spacing: -0.8px; */
  font: normal 25px/34px Source Serif Pro;
}
.active_tool {
  background: #ffffff45;
  border-radius: 15px;
  width: 104px;
  margin: 0px 0px -20px 0px;
}

.tools_for_everyone {
  margin: 370px 0px 0px 32px;

  gap: 123px;
  display: flex;
}
.evre_heading {
  width: 501px;
  height: 41px;
  text-align: left;
  font: normal 700 30px/41px Source Serif Pro;
}
.brd_div {
  border-left: 10px solid #ea006c;
  height: 750px;
  margin: 40px 0px 0px 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 57px;
  border-radius: 7px;
}
.evre_sup {
  margin: 2px 0px 0px 50px;
  height: 41px;
  font: normal 700 30px/41px Source Serif Pro;
}
.evre_img {
  width: 710px;
  height: 747px;
  overflow: hidden;
  margin-top: 59px;
  border-radius: 5px 0 0 5px;
  box-shadow: -1px 1px 5px 1px #00000029;
}

.img_w100_h100 {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.img_w100_h100_2 {
  width: 186%;
  height: 100%;
  opacity: 1;
}
.new_content {
  margin: 80px 0px 76px 33px;
  height: 550px;
  display: flex;
  gap: 36px;
}
.env_div {
  width: 1326px;
  height: 473px;
  background: url(../../assets/home/Screenshot\ 2024-02-13\ at\ 6.40.15\ PM@2x.png);
  margin: 0px 0px 74px 20px;
  border-radius: 25px;
  opacity: 1;
  background-size: cover;

  display: flex;
  gap: 20px;
}

.env_lnk {
  top: 308px;
  left: 125px;
  display: flex;
  gap: 20px;
  font: 600 20px/28px source serif Pro;
}

.con_heading {
  margin: 40px 0px 0px 0px;
  width: 460px;
  height: 82px;
  font-size: 30px;
  color: #000000;
}
.main_con {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.con_cont {
  font-size: 18px;
  width: 594px;
  height: 75px;
  opacity: 100%;
  color: #000000;
  letter-spacing: 0.7px;
}
.con_link {
  display: flex;
  gap: 15px;
}
._link {
  /* width: 294px; */
  font-size: 20px;
  height: 28px;
  margin: 6px 0px 0px 0px;
}
.c_black {
  color: black;
}
.con_icon {
  width: 40px;
  height: 40px;
}
.con_img {
  width: 670px;
  height: 550px;
}
.m_w_simpler {
  height: 377px;
  background: url(../../assets/Group\ 4923.png);
  width: 1366px;
  background-size: cover;
  border-radius: 25px;
  overflow: hidden;
  margin: 0px 0px 4px 77px;
}
.bd_rad_19 {
  border-radius: 19px;
}
.mw_image {
  padding: 32px 0px 0px 0px;
  display: flex;
}
.mw_img {
  width: 130px;
  height: 186px;
  top: 28px;
}

.dis_gp_55 {
  display: flex;
  gap: 55px;
  margin-top: -6px;
}

.new_icons {
  width: 134px;
  height: 123px;
  opacity: 1;
}
.img_bd_r {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 5px 5px 0px;
  opacity: 1;
}

.img_bd_r_l {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px 0px 3px 5px;
  opacity: 1;
}
.work_btn_n {
  background: #3977cb 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf2;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
}

.mw_center {
  width: 739px;
  font-family: serif;
}
.mw_top {
  width: 471px;
  height: 69px;

  top: 50px;
  left: 448px;
  font: 601 50px/69px source serif Pro;
  text-align: left;
  color: #e9edf2;
  opacity: 100%;
}
.mw_botom {
  width: 739px;
  height: 54px;
  text-align: left;
  font: 200 40px/54px source serif Pro;
  color: #e9edf2;

  top: 135px;
  left: 314px;
}
.mw_right_img {
  width: 103px;
  height: 206px;
  top: 32px;
  right: 0;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}
.bt_img {
  text-align: left;
  width: 398px;
  height: 117px;
  top: 238px;
  border-radius: 19px;
  opacity: 1;
  left: -59px;
}
.btn_ {
  width: 175px;
  height: 50px;
  top: 225px;
  left: 596px;
  text-align: left;
}
.regt_img {
  width: 400px;
  text-align: left;
  /* margin: -20px 0px 0px 1183px; */
  height: 105px;
  top: 255px;
  right: 0;
  left: 1188px;
}

.postion_rela {
  position: relative;
}
.postion_abs {
  position: absolute;
}

.bg_cd_img_4_17 {
  width: 214px;
  height: 266px;
  left: 115px;
  top: 523px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}
.bg_cd_img_12_14 {
  width: 228px;
  height: 260px;
  left: 212px;
  top: 638px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}
.bg_cd_img_50_58 {
  width: 206px;
  height: 217px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  left: 370px;
  top: 586px;
}
.bg_cd_img_8 {
  width: 431px;
  height: 214px;
  left: 132px;
  top: 1049px;
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}
.bg_cd_img_6 {
  width: 338px;
  height: 145px;
  left: 274px;
  top: 1030px;
  border-radius: 5px;
  box-shadow: 0px 1px 6px #00000029;
  opacity: 1;
}
.bg_cd_img_9 {
  left: 79px;
  top: 1077px;
  width: 316px;
  height: 123px;
  border-radius: 5px;
  box-shadow: 0px 1px 6px #00000029;
  opacity: 1;
}
.bg_cd_img_7 {
  width: 75px;
  height: 217px;
  left: 310px;
  top: 1041px;
  border-radius: 5px;
  box-shadow: 0px 1px 6px #00000029;
  opacity: 1;
}
.bg_cd_img_3_43 {
  width: 322px;
  height: 313px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  top: 1380px;
  left: 153px;
}
.bg_cd_img_f_59 {
  box-shadow: 10px 10px 10px #00000029;
  border-radius: 10px;
  opacity: 1;
  left: 314px;
  width: 225px;
  height: 260px;
  top: 1516px;
}
