.rsm_main {
  margin: 100px 0px 75px 0px;
  height: 666px;
  width: 100%;
  background: url(../../assets/RelationshipManager1/Rectangle\ 5548@2x.png);
  font-family: serif;
  opacity: 1;
}
.rs_firstsec {
  display: flex;
  gap: 2%;
  padding: 101px 0px 0px 52px;
}
.rs_left_content {
  text-align: left;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 73%;
}
.rs_first_heading {
  width: 100%;
  color: #000000;
  opacity: 100%;
  font: 701 40px/54px Source Serif Pro;
}
.rs_fsec_content {
  width: 95%;

  color: #000000;
  opacity: 1;
  font: 25px/34px Source Serif Pro;
}
.rs_fsec_img {
  width: 25.5%;
  height: 443px;
  opacity: 1;
  overflow: hidden;
}
.rs_img {
  width: 124%;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  border-radius: 10px;
}
.rs_sec_section {
  width: 96%;

  display: flex;
  gap: 1.5%;
  font-family: serif;
  height: 550px;
  text-align: left;
  margin: 0px 0px 35px 2.5%;
}
.rs_sec_img {
  width: 51%;
  height: 550px;
  opacity: 1;
}

.rs_new_s {
  width: 48%;
}
.rs_sec_heading {
  margin: 40px 0px 30px 0px;
  width: 98%;
  text-align: left;
  opacity: 1;
  color: #000000;
  font: 701 30px/41px Source Serif Pro;
}
.rs_sec_content {
  width: 100%;
  text-align: left;
  opacity: 1;
  color: #000000;
  font: 18px/25px Source Serif Pro;
}
.rs_sec_lnk {
  font-size: 20px;
  margin: 7px 0px 0px 0px;
  line-height: 20px;
}
.rs_icon_link {
  display: flex;
  gap: 30px;
  margin: 5px 0px 0px 0px;
}
.rs_third_section {
  display: flex;
  gap: 20px;
  margin-bottom: 97px;
  font-family: serif;
  opacity: 1;
}
.rs_third_imgs {
  width: 48.53%;
  height: 540px;
  position: relative;
  background: url(../../assets/RelationshipManager1/Group\ 4972@2x.png);
  background-size: 100% 100%;
  border-radius: 25px;
}
.fo_im {
  width: 65%;
  height: 39.5%;
  position: absolute;
  opacity: 1;
  top: 4.52%;
  left: 3.4%;
}
.fo_ig {
  width: 74.35%;
  height: 39.7%;
  position: absolute;
  opacity: 1;
  top: 22%;
  left: 20%;
}
.img7 {
  width: 21%;
  height: 75%;
  position: absolute;
  opacity: 1;
  top: 15%;
  left: 31%;
}
.img9 {
  width: 66.66%;
  height: 31%;
  opacity: 1;
  top: 63%;
  left: 29%;
  position: absolute;
}
.img10 {
  width: 36%;
  height: 41px;
  opacity: 1;
  top: 70%;
  left: 12px;
  position: absolute;
}
.img11 {
  top: 80% !important;
}
.rs_third_sec_content {
  width: 46%;
  display: flex;
  flex-direction: column;
  gap: 7.4%;
  text-align: left;
}
.third_sec_heading {
  width: 95%;
  margin: 35px 0px 0px 0px;
  color: #000000;
  opacity: 1;
  font: 800 30px/41px Source Serif Pro;
}
.third_sec_content {
  width: 100%;
}
.third_sec_ic_content {
  display: flex;
  flex-direction: column;
  gap: 10%;
}
.thrd_icon {
  display: flex;
  gap: 15px;
}
.new_icon_height {
  height: 100%;
  opacity: 1;
}
.width_549 {
  width: 87%;
}
.width_537 {
  width: 86%;
}
.width_560 {
  width: 90%;
}
.coll_heading {
  width: 54%;
  margin: 0px 0px 40px 23%;
}
.heading_30_41_left_blk {
  /* font-size: 30px; */
  text-align: left;
  /* line-height: 41px; */
  color: #000000;
  opacity: 100%;
  /* font-family: serif; */
  font: normal 700 30px/41px Source Serif Pro;
}
.rs_mg_bottom {
  margin-bottom: 81px !important;
}
.rs_sec_heading_ {
  width: 65%;
  margin: 0px 0px 40px 2.4%;
}
.rs_big_crd {
  width: 96%;
  position: relative;
  height: 1155px;
  margin: 0px 0px 4.6% 2.4%;
  background: url(../../assets/RelationshipManager1/Group\ 4985@2x.png);
  background-size: 100% 100%;
  font-family: serif;
  border-radius: 25px;
}
.bg_bck_1st_img {
  width: 30%;
  height: 366px;
  position: absolute;
  top: 5%;
  left: 3.7%;
  opacity: 1;
}
.br_5 {
  border-radius: 5px;
}
.bg_bck_2nd_img {
  width: 18.76% !important;
  left: 40.69%;
}
.bg_bck_3rd_img {
  width: 22.38%;
  height: 250px;
  top: 10.2%;
  position: absolute;
  left: 70.23%;
  border-radius: 5px !important;
}
.fs_18_h_25_left_blk {
  /* font-size: 18px;
  line-height: 25px; */
  color: #000000;
  text-align: left;
  font: 18px/25px Source Serif Pro;
}
.fs_first_div {
  width: 85%;
  margin: 20px 0px 0px 6px;
}
.fs_second_div {
  width: 104%;
  height: 75px;
  margin: 20px 0px 0px -12px;
}
.fs_thrd_div {
  width: 105%;
  height: 75px;
  margin: 78px 0px 0px 6px;
}
.second_cont_bg {
  position: relative;
  top: 587px;
}

.act_bg {
  width: 25.32%;
  height: 312px;
  left: 6%;
  top: 56.53%;
}
.ad_bg {
  width: 23%;
  height: 325px;
  top: 56%;
  left: 71.76%;
}
.ad_sec_bg {
  width: 18%;
  position: absolute;
  left: 40%;
  height: 366px;
  top: 54%;
}
.act_content_bg {
  margin: 47px 0px 0px -20px;
  width: 123%;
}
.ad_content_bg {
  width: 130%;

  margin: 26px 0px 0px -4px;
}
.ad_sec_content_bg {
  width: 105%;
  margin: 33px 0px 0px -3px;
}

.ad_bg_cont {
  width: 70%;
  margin: 0px 0px 40px 2.4%;
}
.rs_last_sec {
  display: flex;
  width: 96%;
  margin: 0px 0px 94px 2.4%;
  font-family: serif;
  opacity: 1;
  gap: 3.6%;
  height: 471px;
}
.rs_last_s_img {
  width: 51.53%;
  height: 466px;
  margin-top: 5px;
  opacity: 1;
}
.rs_last_icon_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 45%;
}
.rs_last_icon_ {
  display: flex;
  gap: 15px;
}
.rs_last_content {
  width: 100%;
}
.rs_last_conte {
  width: 84%;
}
