.tandS_main{
    /* width: 363px;
    height: 54px; */
    opacity: 1;

}
.t_s{
    margin-top: 82px;
    color: #ffffff;
}

.new_tand_c{
    width: 97%;
    font: normal 20px/28px source serif Pro;
    display: flex;
    flex-direction: column;
    gap: 25px;
    
    text-align: left;
    opacity: 1;
 
    letter-spacing: 0px;
}
.t_s_1_heading{
    width: 486px;
    height: 82px;
    margin: 0px 0px 30px 52px;
    opacity: 1;
}
.t_s_2_div{
    width: 90.62%;
    margin: 0px 0px 5px 52px;
    font-family: serif;
    font: 18px/25px source serif pro;
    text-align: left;
    font-weight: 700;
}
.mt_b_22{
    margin-bottom: 22px;
}
.mt_b_30{
    margin-bottom: 28px;
}