.slide{
    width: 98%;
    margin-top: 40px;
}
.slide_img{
    /* width: 250px; */
    height: 124px;
}
.tab_width{
    width: 0;
}