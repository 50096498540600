.vt_mt_hb_main {
  margin-top: 100px;
}
.vt_first_section {
  width: 100%;
  height: 666px;
  background: url(../../assets/virtualmeetingicon/Rectangle\ 5548@2x.png);
  opacity: 1;
  background-size: cover;
  display: flex;
  gap: 26px;
}
.vt_rt_sec {
  width: 66%;
  margin: 90px 0px 0px 52px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 40px;
  font-family: serif;
}
.vt_rt_heading {
  width: 100%;
  color: #000000;
  font: 700 50px/69px Source Serif Pro;
}
.vt_rt_content {
  width: 92%;
  color: #000000;
  font: 400 25px/34px Source Serif Pro;
}
.vt_rt_img {
  width: 29%;
  height: 395px;
  margin-top: 140px;
  opacity: 1;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 0px 0px 10px;
}

.vt_rt_image {
  width: 179%;
  height: 100%;
  opacity: 1;
}
.vt_second_sec {
  width: 93%;
  height: 725px;
  margin: 29px 0px 7% 4%;
  font-family: serif;
  background: url(../../assets/virtualmeetingicon/Group\ 4988@2x.png);
  background-size: 100% 100%;
  border-radius: 25px;
}
.vt_sec_heading {
  padding: 46px 0px 0px 24%;
  width: 75%;
  text-align: center;
  color: #ffffff;
  font: 500 45px/62px Source Serif Pro;
}
.vt_sec_content {
  width: 75%;
  margin: 25px 0px 0px 13%;
  font: 300 25px/34px Source Serif Pro;
  text-align: center;
  color: #ffffff;
  opacity: 1;
}
.line_h_34 {
  line-height: 34px;
}
.vt_sec_fi {
  display: flex;
  gap: 112px;
}
.vt_sec_fi_ico {
  width: 6%;
  height: 6%;
}
.vt_sec_fi_icon {
  width: 6%;
  height: 6%;
  margin: 0px 0px 0px 18%;
}
.vt_sec_row {
  display: flex;
  margin: 6% 0px 0px 3.5%;
}
.vt_sec_2row {
  display: flex;
  margin: 50px 0px 0px 42px;
}
.vt_sec_ro_ele {
  width: 27%;
  text-align: left;
  margin: 1% 0px 0px 1%;
  color: #ffffff;
  font: 700 30px/41px Source Serif Pro;
}
.vt_width {
  width: 34%;
}

.vt_cont_sec {
  display: flex;
  margin: 0px 0px 0px 10.36%;
  gap: 17.86%;
}
.vt_cont_sec_1 {
  text-align: left;
  width: 38%;
  color: #ffffff;
  font: 300 25px/34px Source Serif Pro;
}
.vt_cont_sec_2 {
  width: 41%;
  text-align: left;
  color: #ffffff;
  font: 300 25px/34px Source Serif Pro;
}
.md_remote {
  width: 70%;
  font: 45px/62px source serif Pro;
  text-align: left;
  font-family: serif;
  margin: 0px 0px 4% 5.4%;
}
.vt_par_crd_div {
  opacity: 1;
  margin: 0px 0px 6.3% 4%;
  display: flex;
  gap: 60px 30px;
  flex-wrap: wrap;
  width: 94%;
}

.meet_vt {
  display: flex;
  width: 96%;
  height: 550px;
  margin: 0px 0px 4% 3%;
  font-family: sans-serif;
  gap: 8%;
}

.meet_img_vt {
  width: 51.53%;
  opacity: 1;
}
.img_meet {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.meet_content {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 5.5%;
  font-family: serif;
  width: 40%;
}
.meet_sm_content {
  display: flex;
  gap: 10px;
}
.meet_heading {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 40px;
  font: 700 30px/41px Source Serif Pro;
}
.meet_icon {
  width: 40px;
  height: 40px;
  opacity: 1;
}
.meet_p {
  margin: 1.5% 0px 0px 0px;
  opacity: 100%;
  color: #000000;
  font: 400 18px/25px Source Serif Pro;
}
.vt_ratings {
  width: 100%;
  height: 450px;
  opacity: 1;
  background-image: url(../../assets/virtualmeetingicon/Rectangle\ 5552@2x.png);
  margin-bottom: 10%;
  background-size: 100% 100%;
  border-radius: 25px;
}
.vt_rating_content {
  padding: 3.6% 0px 0px 3.6%;
  text-align: left;
  color: #ffffff;
  opacity: 100%;
  font: 800 35px/48px Source Serif Pro;
  width: 70%;
}
.rating_card_main {
  display: flex;
  margin: 7% 0px 0px 3.6%;
  gap: 2.7%;
}
.rating_crd {
  width: 22.5%;
  height: 155px;
  background-size: 100% 100%;
  opacity: 1;
  display: flex;
  gap: 15.5px;
  box-shadow: 10px 10px 10px #00000029;
border-radius: 10px;
}
.rating_line {
  height: 125px;
  margin: 17px 0px 0px 15px;
}
.h_100 {
  height: 100%;
}
.rat_percent {
  width: 70px;
  height: 48px;
  font-size: 35px;
  text-align: left;
  font-weight: 600;
  font-family: serif;
  margin: 20px 0px 10px 0px;

}
.rt_text {
  height: 50px;
  opacity: 1;
  font: 18px/25px Source Serif Pro;
}
.vt_suffling {
  display: flex;
  width: 87.4%;
  margin: 0px 0px 10% 3.6%;
  gap: 3.6%;
  font-family: serif;
  opacity: 1;
  height: 501px;
}
.vt_suff_img {
  width: 46.83%;
  height: 480px;
  margin-top: 21px;
  opacity: 1;
}
.vt_suff_header {
  width: 100%;
  font: 701 30px/41px Source Serif Pro;
}
.vt_suff_main {
  display: flex;
  gap: 5%;
  flex-direction: column;
  text-align: left;
  opacity: 1;
  width: 50%;
}
.vt_stuff_content {
  width: 100%;
  letter-spacing: -0.1px;
}
.vt_sec_stuff {
  width: 98%;
}
.ft_18_l_ht_25 {
  /* font-size: 18px; */
  color: #000000;
  /* line-height: 25px; */
  font: 400 18px/25px Source Serif Pro;
}
.vt_new_wt{
width: 42%;
}

.vt_wt_board{
  display: flex;
  width: 93%;
  margin-left: 3.6%;
  gap: 6%;
  font-family: serif;
  height: 466px;
  margin-bottom: 118px;
  margin-bottom: 8.7%;
}
.vt_wt_img{
  width: 52%;
    height: 466px;
    opacity: 1;
}
.vt_wt_cont_header{  
    text-align: left;
    margin-bottom: 25px;
    font: 701 30px/41px Source Serif Pro;

}
.vt_wt_cont{
    margin-bottom: 70px;
    text-align: justify;
}
.vt_wt_link{
    display: flex;
    gap: 4%;
}
.vt_link_tag{
    /* font-size: 25px; */
    color: #000000;
    margin-top: 3px;
    font: 601 20px/28px Source Serif Pro;
}